import React from "react";
import { WizardStepTitle, WizardStepSubtitle, WizardStepBody, } from "./TailwindComponents";

export default function WizardFormTemplate(props) {
  const { fieldSet } = props;
  return (
    <>
      <WizardStepTitle>{fieldSet.title}</WizardStepTitle>
      <WizardStepSubtitle>{fieldSet.subtitle}</WizardStepSubtitle>
      <WizardStepBody>{fieldSet.content}</WizardStepBody>
    </>
  );
}
