import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";

import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";

import {
  WizardStepTitle,
  WizardStepSubtitle,
  WizardStepBody,
  WizardStepPrompt,
  WizardStepIcon,
  WizardContentContainer,
  WizardActionPanel,
  WizardActionButton,
  WizardChipCloud,
  WizardCenteredRow,
} from "./TailwindComponents";

import AddNewMultiSelectItem from "../../../../../../ux/AddNewMultiSelectItem";
import { getWizardMultiSelectOptions } from "../../../../../../../utils/redux/actions/dataActions/playbookActions";
import InlineLoader from "../../../../../../ux/InlineLoader";
import { useParams } from "react-router-dom";
import SwitchWithToggle from "../../../../../../ux/SwitchWithLabel";
import WizardCardTitle from "./WizardCardTitle";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const handleChange = (onChange, e, value) => {
  let optionArray = value;
  if (optionArray.includes(e)) {
    optionArray = optionArray.filter((item) => item !== e);
  } else {
    optionArray.push(e);
  }
  onChange(optionArray);
};

export default function WizardConfirmVariablesTemplate(props) {
  const { fieldSet, form, active, stepper } = props;
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    getData(fieldSet.requestCode);
  }, []);

  const getData = async (requestCode) => {
    setLoading(true);
    const data = await dispatch(
      getWizardMultiSelectOptions(requestCode, params.playbookId)
    );
    setOptions(data.options);

    // if option is required, add it to the value array
    const requiredOptions = data.options.filter((option) => option.required);
    setLoading(false);
    return;
  };

  const nextStep = async () => {
    const validate = await form.trigger(fieldSet.fieldName);
    if (validate) {
      stepper("next");
    }
  };

  return (
    <>
      <WizardContentContainer>
        {/* <WizardStepIcon className={fieldSet.color}>
          <fieldSet.icon className="h-6 w-6 text-white" />
        </WizardStepIcon> */}

        <WizardCardTitle
          title={fieldSet.title}
          icon={fieldSet.icon}
          subtitle={fieldSet.subtitle}
          error={
            form.formState.errors[fieldSet.fieldName] &&
            form.formState.errors[fieldSet.fieldName].message
          }
        />

        <WizardStepPrompt>{fieldSet.prompt}</WizardStepPrompt>

        {loading && <InlineLoader />}


          {options.map((option, index) => (
            
              <Controller
              key={index}
                defaultValue={option.switchDefault}
                control={form.control}
                name={option.fieldName}
                render={({ field: { value, onChange } }) => (
                  <SwitchWithToggle
                    defaultValue={option.switchDefault}
                    title={'Use AI to adapt ' + option.label}
                    description={option.value}
                    onChange={onChange}
                  />
                )}
              />
          ))}
        
      </WizardContentContainer>

      <WizardActionPanel>
        <WizardActionButton onClick={() => stepper("back")}>
          Back
        </WizardActionButton>
        <WizardActionButton onClick={nextStep}>Next</WizardActionButton>
      </WizardActionPanel>
    </>
  );
}
