import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { get } from "lodash";

import { useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../../../utils/redux/actions/uiActions";
import InlineFullLoader from "../../../../components/ux/inlineFullLoader";

import UniversalTinyMCE from "../../../universalEditors/UniversalTinyMCE";
import useNavBarTitle from "../../../../utils/hooks/useNavBarTitle";
import { strings } from "../../../../utils/strings/en-us";
import QuickStartWizard from "./QuickStartWizard";

export default function QuickStartEditor() {
  let params = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.v2Data);
  const product = get(data, `products.${params.productId}`, {});
  const htmlDocument = get(
    data,
    `products.${params.productId}.notebook.htmlDocument`,
    ""
  );
  const user = useSelector((state) => state.user);
  const ui = useSelector((state) => state.ui);
  const [html, setHTML] = useState(null);
  const [originalDocument, setOriginalDocument] = useState(null);


  const handleBlur = (content) => {
    setHTML(content);
  };

  // Shrink Sidebar
  useEffect(() => {
    dispatch(toggleSidebar(true));

    // TODO: Implement a proper page title mechanism at a higher level
    dispatch({
      type: "SET_NAV_BAR",
      payload: {
        title: "Edit Positioning",
        sticky: true,
        stickyOffset: -1,
      },
    });
  }, []);

  const blankDocument = {
    id: "123",
    content: "<p>test original</p>",
    editorView: "classicStyle",
  };

  const returnedDocument = {
    content: htmlDocument,
    editorView: "classicStyle",
  };

  useEffect(() => {
    if (htmlDocument !== "") {
      setOriginalDocument(returnedDocument);
    } else {
      setOriginalDocument(blankDocument);
    }
  }, [htmlDocument]);

  return originalDocument ? (
    <>
      <h1 id="primary-heading" className="sr-only">
        {" "}
        Asset Editor{" "}
      </h1>

      <main
        className={`fixed transition-all duration-700 bottom-0 top-32 z-10 right-[385px] ${
          ui.shrinkSidebar ? " left-16" : "left-[285px]"
        }`}
      >
        <UniversalTinyMCE document={originalDocument} handleBlur={handleBlur} />
      </main>
      <section className="fixed right-0 top-16 bottom-0 w-[385px]">
        <QuickStartWizard html={html} />
      </section>
    </>
  ) : (
    <InlineFullLoader />
  );
}
