import tw from "tailwind-styled-components";

const WizardContainer = tw.div`
    w-screen
    max-w-lg
    max-h-[500px]
    h-screen
    relative
    overflow-hidden
`;

const WizardTitleContainer = tw.div`
    flex
    flex-col
    items-center
    justify-center
    gap-6
    h-full
    px-10
    relative
    overflow-hidden
`;

const WizardStepTitle = tw.h3`
text-base
font-semibold
leading-6
text-gray-900
shrink-0
text-center
`;
const WizardStepSubtitle = tw.p`
text-sm
text-gray-500
text-center
shrink-0
`;

const WizardStepBody = tw.p`
text-sm
text-gray-500
text-center
shrink-0
`;
const WizardStepPrompt = tw.p`
text-sm
text-gray-500
text-center
shrink-0
`;

const WizardStepIcon = tw.div`
inline-flex
items-center
justify-center
h-10
w-10
rounded-lg
shrink-0
`;

const WizardTitleIcon = tw.div`
inline-flex
items-center
justify-centerx
w-24
h-24
rounded-lg
shrink-0
shadow-lg
p-5
mt-6
`;

const WizardTitleTitle = tw.h3`
text-xl
font-semibold
leading-6
text-gray-900
shrink-0
text-center
`;

const WizardStepContainer = tw.div`
absolute
left-0
right-0
bottom-0
top-0
duration-500
transition-all
p-4
flex
flex-col
`;

const WizardContentContainer = tw.div`
flex
flex-col
space-y-4
grow
overflow-y-auto
`;

const WizardCenteredRow = tw.div`
flex
justify-center
w-full
`

const WizardActionPanel = tw.div`
flex
justify-center
pt-4
shrink-0
gap-4
`;

const WizardActionButton = tw.button`
-ml-px
items-center
rounded-md
border
border-gray-100
bg-blue-500
px-10
py-2
text-sm
font-medium
text-gray-100
hover:bg-blue-800
focus:z-10
focus:border-blue-600
focus:outline-none
focus:ring-1
focus:ring-blue-600
inline-flex"
`;

const WizardSecondaryButton = tw.button`
relative -ml-px items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-blue-50 focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 inline-flex
`;

const WizardChipCloud = tw.div`
flex
w-full
flex-wrap
gap-2
justify-center
grid
grid-cols-2
`

export {
  WizardActionButton,
  WizardContainer,
  WizardStepContainer,
  WizardStepTitle,
  WizardStepSubtitle,
  WizardStepBody,
  WizardStepPrompt,
  WizardStepIcon,
  WizardActionPanel,
  WizardContentContainer,
  WizardChipCloud,
  WizardCenteredRow,
  WizardTitleIcon,
  WizardTitleTitle,
  WizardTitleContainer,
  WizardSecondaryButton,
  tw,
};
