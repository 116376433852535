import { Popover } from "@headlessui/react";
import { BoltIcon } from "@heroicons/react/24/solid";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import Tooltip from "./Tooltip";

export default function AssetFormTextFieldWithSuggestions(props) {
  const {
    fieldName,
    options,
    register,
    errors,
    label,
    placeholder,
    required,
    maxLength,
    minLength,
    type,
    defaultValue,
    autoComplete,
    noLabel,
    setValue,
    tooltipContent,
  } = props;

  // Consistent Patterning
  const enforcePattern = (type) => {
    let output = {};

    // Email Pattern
    if (type === "email") {
      output = {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email address",
      };
    }

    return output;
  };

  //Get field options if they exist
  const fieldOptions = options ? options.options : [];

  //Should we disable the field?
  const editable = options ? options.editable : true;

  return (
    <div className={`m-1 ${!editable && "cursor-not-allowed"}`}>
      <div className="flex flex-row justify-between">
        {!noLabel && (
          <label className="text-sm font-medium text-gray-700">
            {label} {required && <span className="text-red-500">*</span>}{" "}
            {!editable && (
              <span className="ml-1 text-xs text-gray-500">
                (Locked to selected playbook)
              </span>
            )}
          </label>
        )}
        <div className="ml-1">
          <Tooltip content={tooltipContent} />
        </div>
      </div>

      <div
        className={`p-0 relative flex ${
          errors[fieldName]
            ? "block w-full appearance-none rounded-md border border-red-600 placeholder-red-400 shadow-sm focus-within:border-blue-500 focus-within:border-2 focus-within:m-[-1px]  focus-within:ring-blue-500 sm:text-sm"
            : "block w-full appearance-none rounded-md border border-gray-300 placeholder-gray-400 shadow-sm focus-within:border-blue-500 focus-within:border-2 focus-within:m-[-1px]  focus-within:ring-blue-500 sm:text-sm"
        } ${editable ? "bg-white" : "bg-gray-100 cursor-not-allowed"}`}
      >
        {fieldOptions &&
          Array.isArray(fieldOptions) &&
          fieldOptions.length > 0 && (
            <>
              <Popover>
                <div className="flex h-full">
                  <Popover.Button>
                    <BoltIcon className="w-8 h-full p-2 text-pink-500 shrink-0 rounded-l-md bg-gray-100 hover:bg-pink-500 hover:text-gray-100 transition-all duration-200" />
                  </Popover.Button>
                </div>

                <Popover.Panel>
                  <div className="absolute z-50 divide-y divide-gray-200 w-full bottom-0 min-h-full max-h-44 overflow-scroll left-0 z-2 bg-white border border-gray-200 rounded-md shadow-md">
                    {fieldOptions.map((option, index) => (
                      <Popover.Button
                        key={index}
                        className="p-2 cursor-pointer text-left flex w-full gap-3 hover:bg-gray-100"
                        onClick={() => {
                          setValue(fieldName, option.content);
                        }}
                      >
                        <BoltIcon className="w-5 h-5 shrink-0 text-pink-500" />
                        <p className="pl-1">{option.title}</p>
                      </Popover.Button>
                    ))}
                  </div>
                  <Popover.Button>
                    <div className="fixed left-0 right-0 top-0 bottom-0 bg-black opacity-25 z-40"></div>
                  </Popover.Button>
                </Popover.Panel>
              </Popover>
            </>
          )}

        <TextareaAutosize
          id={fieldName}
          placeholder={placeholder}
          name={fieldName}
          defaultValue={defaultValue}
          type={type}
          autoComplete={autoComplete}
          disabled={!editable}
          required={required}
          {...register(fieldName, {
            required: {
              value: required,
              message: "Required",
            },
            maxLength: {
              value: maxLength,
              message: `Must be less than ${maxLength} characters`,
            },
            minLength: {
              value: minLength,
              message: `Must be more than ${minLength} characters`,
            },
            pattern: {
              ...enforcePattern(type),
            },
          })}
          className={`text-sm resize-none py-1 px-0 m-1 w-full bg-transparent border-none outline-none border-transparent focus:border-transparent focus:ring-0`}
        />
      </div>
      {errors[fieldName] && (
        <p className="mt-2 text-sm text-red-600">{errors[fieldName].message}</p>
      )}
    </div>
  );
}
