import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";

import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";

import {
  WizardStepTitle,
  WizardStepSubtitle,
  WizardStepBody,
  WizardStepPrompt,
  WizardStepIcon,
  WizardContentContainer,
  WizardActionPanel,
  WizardActionButton,
  WizardChipCloud,
  WizardCenteredRow,
} from "./TailwindComponents";

import AddNewMultiSelectItem from "../../../../../../ux/AddNewMultiSelectItem";
import { getWizardMultiSelectOptions } from "../../../../../../../utils/redux/actions/dataActions/playbookActions";
import InlineLoader from "../../../../../../ux/InlineLoader";
import { useParams } from "react-router-dom";
import SwitchWithLabel from "../../../../../../ux/SwitchWithLabel";
import WizardCardTitle from "./WizardCardTitle";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const handleChange = (onChange, e, value) => {
  let optionArray = value;
  if (optionArray.includes(e)) {
    optionArray = optionArray.filter((item) => item !== e);
  } else {
    optionArray.push(e);
  }
  onChange(optionArray);
};

const Option = ({ active, onClick, label, index, required }) => (
  <button
    type="button"
    disabled={required}
    onClick={onClick}
    className={classNames(
      active
        ? "bg-blue-500 border-transparent text-white hover:bg-blue-800 hover:text-white"
        : "bg-white border-gray-300 text-gray-600 hover:bg-blue-50",
      "cursor-pointer gap-3 focus:outline-none border rounded-md py-1 px-3 flex w-auto text-sm flex-0"
    )}
  >
    <div
      className={`transition-all grid items-center duration-500 ${
        active ? "w-0" : "w-5"
      }`}
    >
      <PlusIcon className="w-full" />
    </div>

    <span>{label}</span>

    {!required && (
      <div
        className={`transition-all grid items-center duration-500 ${
          !active ? "w-0" : "w-5"
        }`}
      >
        <XMarkIcon className="w-full" />
      </div>
    )}
  </button>
);

export default function WizardMultiSelectTemplate(props) {
  const { fieldSet, form, active, stepper } = props;
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    getData(fieldSet.requestCode);
  }, []);

  const getData = async (requestCode) => {
    setLoading(true);
    const data = await dispatch(
      getWizardMultiSelectOptions(requestCode, params.playbookId)
    );
    setOptions(data.options);

    // if option is required, add it to the value array
    const requiredOptions = data.options.filter((option) => option.required);
    const requiredValues = requiredOptions.map((option) => option.value);
    form.setValue(fieldSet.fieldName, requiredValues);

    setLoading(false);
    return;
  };

  const addOption = (target, event) => {
    let newOption = event.target.value;
    const newOptions = [...options, { value: newOption, required: false }];
    form.setValue(fieldSet.fieldName, [
      ...form.watch(fieldSet.fieldName),
      newOption,
    ]);
    setOptions(newOptions);
  };

  const nextStep = async () => {
    const validate = await form.trigger(fieldSet.fieldName);
    if (validate) {
      stepper("next");
    }
  };


  return (
    <>
      <WizardContentContainer>
        {/* <WizardStepIcon className={fieldSet.color}>
          <fieldSet.icon className="h-6 w-6 text-white" />
        </WizardStepIcon> */}

        <WizardCardTitle
          title={fieldSet.title}
          icon={fieldSet.icon}
          subtitle={fieldSet.subtitle}
          error={
            form.formState.errors[fieldSet.fieldName] &&
            form.formState.errors[fieldSet.fieldName].message
          }
        />

        <WizardStepPrompt>{fieldSet.prompt}</WizardStepPrompt>

        {loading && <InlineLoader />}

        <WizardChipCloud>
          <Controller
            defaultValue={[]}
            control={form.control}
            name={fieldSet.fieldName}
            rules={{
              required: { value: true, message: fieldSet.requiredErrorMessage },
            }}
            render={({ field: { value, onChange } }) =>
              options.map((option, index) => (
                <Option
                  key={index}
                  label={option.value}
                  active={value.includes(option.value)}
                  required={option.required}
                  onClick={() => handleChange(onChange, option.value, value)}
                />
              ))
            }
          />
        </WizardChipCloud>

        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="bg-white px-2 text-gray-500">
              {fieldSet.customTagDivider} {loading && "while you wait..."}
            </span>
          </div>
        </div>

        <WizardCenteredRow>
          <AddNewMultiSelectItem
            defaultText={fieldSet.customTagPrompt}
            target="new"
            onEnd={addOption}
          />
        </WizardCenteredRow>

        {fieldSet.switch && (
          <WizardCenteredRow>
            {/* <WizardStepSubtitle>{fieldSet.switchLabel}</WizardStepSubtitle> */}

            <Controller
              defaultValue={fieldSet.switchDefault}
              control={form.control}
              name={fieldSet.switchFieldName}
              render={({ field: { value, onChange } }) => (
                <SwitchWithLabel
                  defaultValue={fieldSet.switchDefault}
                  title={fieldSet.switchLabelTitle}
                  description={fieldSet.switchLabelDescription}
                  onChange={onChange}
                />
              )}
            />
          </WizardCenteredRow>
        )}
      </WizardContentContainer>

      <WizardActionPanel>
        <WizardActionButton onClick={() => stepper("back")}>
          Back
        </WizardActionButton>
        <WizardActionButton onClick={nextStep}>Next</WizardActionButton>
      </WizardActionPanel>
    </>
  );
}
