import React, { useEffect } from "react";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { Cog6ToothIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";
import { pendingInviteData } from "../../utils/redux/actions/dataActions";
import { UserCircleIcon, UserIcon } from "@heroicons/react/24/outline";



export default function CollaboratorList(props) {
  // Get Component Params
  const { small, showPendingInvites } = props;

  // Actions
  const dispatch = useDispatch();
  let params = useParams();

  // Get Data in State
  const data = useSelector((state) => state.v2Data);
  let organizations = get(data, "organizations");
  let selectedOrganization = get(organizations, params.organizationId);
  let collaborators = get(selectedOrganization, "rbac", {});
  let pendingInvites = get(selectedOrganization, "pendingInvites", []);

  //Get Pending Invites if Requested
  useEffect(() => {
    showPendingInvites && dataActions();
  }, []);

  const dataActions = () => {
    dispatch(pendingInviteData({ organizationId: params.organizationId }));
    console.log("get pending invites");
  };

  // Return Component
  return selectedOrganization.loaded && (
    <>
      <ul className="-my-5 divide-y divide-gray-200">
        {collaborators ? (
          Object.keys(collaborators).map((keyName, keyIndex) => (
            <li key={keyIndex} className={small ? "py-1" : "py-4 px-6"}>
              <div className="flex items-center space-x-4">
                {!small && <div className="flex-shrink-0">
                <UserIcon
                    className="h-8 w-8 text-gray-400"
                    aria-hidden="true"
                  /></div>}
                <div className="min-w-0 flex-1">
                  <p
                    className={`truncate text-sm font-medium ${
                      small ? "text-gray-600" : "text-gray-600text-gray-900"
                    }`}
                  >
                    {collaborators[keyName].firstName}{" "}
                    {collaborators[keyName].lastName}{" "}
                    {small && collaborators[keyName].role === "owner" && (
                      <span className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs  font-medium text-green-800">
                        {collaborators[keyName].role}
                      </span>
                    )}
                  </p>
                  {!small && (
                    <p className="truncate text-sm text-gray-500">
                      {collaborators[keyName].role}
                    </p>
                  )}
                </div>
                <div>
                  {/* <button className="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-blue-50">
                    {small ? (
                      <Cog6ToothIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    ) : (
                      "Edit"
                    )}
                  </button> */}
                </div>
              </div>
            </li>
          ))
        ) : (
          <li>No Collaborators Yet</li>
        )}
        {showPendingInvites &&
          pendingInvites &&
          pendingInvites.map((invite) => (
            <li key={invite.id} className={small ? "py-1" : "py-4"}>
              <div className="flex items-center space-x-4">
                {!small && <div className="flex-shrink-0">
                  <UserIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  </div>}
                <div className="min-w-0 flex-1">
                  <p
                    className={`truncate text-sm font-medium ${
                      small ? "text-gray-400" : "text-gray-600text-gray-900"
                    }`}
                  >
                    {invite.email}
                    {small && (
                      <span className="inline-block flex-shrink-0 rounded-full bg-gray-100 px-2 py-0.5 text-xs  font-medium text-gray-400">
                        invited
                      </span>
                    )}
                  </p>
                  {!small && (
                    <p className="truncate text-sm text-gray-500">invited</p>
                  )}
                </div>
                <div>
                  <button className="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-blue-50">
                    {small ? (
                      <Cog6ToothIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    ) : (
                      "Edit"
                    )}
                  </button>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </>
  );
}
