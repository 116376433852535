import React from "react";
import AddBenefitsFeaturesButtons from "../product/positioning/forms/BenefitsFeaturesMatrix/AddBenefitsFeaturesButtons";
import BenefitsFeaturesMatrix from "../product/positioning/forms/BenefitsFeaturesMatrix/BenefitsFeaturesMatrix";
import FormMultiSelect from "./FormMultiSelect";
import FormRadioButton from "./FormRadioButton";
import FormTextArea from "./FormTextArea";
import FormTextField from "./FormTextField";
import QuickStartBenefitsMatrix from "../product/notebook/quickStart/QuickStartBenefitsMatrix";

export default function FormFieldSet(props) {
  const {
    step,
    register,
    errors,
    watch,
    fieldSet,
    setValue,
    focusVersion,
    control,
    requireFields,
  } = props;
  return (
    <div className="bg-white px-4 py-5 shadow rounded-lg">
      <fieldset disabled={step === 2} className="mt-2 ">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <span
              className={`inline-flex mb-6 ${fieldSet.color} items-center justify-center h-10 w-10 rounded-lg`}
            >
              <fieldSet.icon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </span>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {/* {strings.positioning.forms.productOverviewTitle} */}
              {fieldSet.name}
            </h3>
            <p className="mt-1 pr-10 text-sm text-gray-500">
              {fieldSet.description}
            </p>
          </div>

          <div className="mt-5 md:col-span-2 md:mt-0 grid grid-cols-1 gap-6">
            {fieldSet.fields.map((field, index) => {
              return (
                <div key={index}>
                  {field.type === "text" && (
                    <FormTextField
                      register={register}
                      errors={errors}
                      fieldName={field.fieldName}
                      placeholder={field.placeholder}
                      label={field.label}
                      isRequired={requireFields && field.isRequired}
                      maxLength={field.maxLength}
                      minLength={field.minLength}
                      disabled={field.disabled}
                      defaultValue={field.defaultValue}
                      type="text"
                    />
                  )}

                  {field.type === "email" && (
                    <FormTextField
                      register={register}
                      errors={errors}
                      fieldName={field.fieldName}
                      defaultValue={field.defaultValue}
                      placeholder={field.placeholder}
                      label={field.label}
                      isRequired={requireFields && field.isRequired}
                      maxLength={field.maxLength}
                      minLength={field.minLength}
                      disabled={field.disabled}
                      type="email"
                    />
                  )}

                  {field.type === "number" && (
                    <FormTextField
                      register={register}
                      errors={errors}
                      fieldName={field.fieldName}
                      placeholder={field.placeholder}
                      label={field.label}
                      isRequired={requireFields && field.isRequired}
                      maxLength={field.maxLength}
                      minLength={field.minLength}
                      disabled={field.disabled}
                      type="number"
                    />
                  )}
                  {field.type === "textarea" && (
                    <FormTextArea
                      register={register}
                      errors={errors}
                      fieldName={field.fieldName}
                      placeholder={field.placeholder}
                      label={field.label}
                      isRequired={requireFields && field.isRequired}
                      maxLength={field.maxLength}
                      minLength={field.minLength}
                      disabled={field.disabled}
                      rows={field.rows}
                      type="text"
                      showCount={
                        watch(field.fieldName) && watch(field.fieldName).length
                      }
                    />
                  )}
                  {field.type === "radio" && (
                    <FormRadioButton
                      register={register}
                      errors={errors}
                      fieldName={field.fieldName}
                      placeholder={field.placeholder}
                      label={field.label}
                      isRequired={requireFields && field.isRequired}
                      options={field.options}
                      defaultValue={field.defaultValue}
                      columns={field.columns}
                      control={control}
                      watch={watch(field.fieldName)}
                      disabled={field.disabled}
                    />
                  )}
                  {field.type === "multiSelect" && (
                    <FormMultiSelect
                      register={register}
                      errors={errors}
                      fieldName={field.fieldName}
                      placeholder={field.placeholder}
                      label={field.label}
                      isRequired={requireFields && field.isRequired}
                      options={field.options}
                      defaultValue={field.defaultValue}
                      columns={field.columns}
                      control={control}
                      disabled={field.disabled}
                      watch={watch(field.fieldName)}
                    />
                  )}
                  {field.type === "benefitsFeaturesMatrix" && (
                    <BenefitsFeaturesMatrix errors={errors} />
                  )}
                  {field.type === "benefitsFeaturesMatrixHookForm" && (
                    <QuickStartBenefitsMatrix
                    fieldName={field.fieldName}
                    control={control}
                    errors={errors}
                    watch={watch(field.fieldName)}
                    setValue={setValue}
                  />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </fieldset>
    </div>
  );
}
