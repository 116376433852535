const { PencilIcon, PuzzlePieceIcon } = require("@heroicons/react/24/outline");
const { defaultFieldTypes } = require("./defaultFieldTypes");

exports.featureAnnouncementEmail = {
  overview: {
    id:
      "featureAnnouncementEmail",
    name:
      "Feature Announcement Email",
    description:
      "Write an email to introduce a new product feature to customers.",
    pageTitle:
      "New Feature Announcement Email",
    pageDescription:
      "To generate your email, complete the information below. Certain fields are optional, but providing more information will result in a more personalized email.",
    icon:
      PuzzlePieceIcon,
    color:
      "bg-blue-500",
    playbookTooltip:
      "Pre-fill this form with your product information?",
  },
  fieldSet: [
    {
      id: "targetAudience",
      title: "Target Audience",
      fields: [
        {
          id: "industries",
          title: "Industry",
          prompt: "Target industry.",
          fieldName: "industries",
          maxLength: 200,
          type: "textInput",
        },
        {
          id: "usecases",
          title: "Use Cases",
          prompt: "Target use case.",
          fieldName: "usecases",
          maxLength: 200,
          type: "textInput",
        },
        {
          id: "size",
          title: "Organization Size",
          prompt: "Target organization size.",
          fieldName: "size",
          required: true,
          requiredErrorMessage: "You must provide a target organization size.",
          type: "sizeMultiSelect",
          choices: [
            { value: "all", label: "All" },
            { value: "small", label: "Small" },
            { value: "medium", label: "Medium" },
            { value: "large", label: "Large" },
            { value: "enterprise", label: "Enterprise" },
          ],
        },
        {
          id: "jobTitle",
          title: "Job Title",
          prompt: "Enter a job title.",
          fieldName: "jobTitle",
          maxLength: 200,
          type: "textInput",
        },
      ],
    },
    {
      id: "emailContent",
      title: "Email Content",
      fields: [
        {
          id: "productName",
          title: "Product Name",
          prompt: "Enter the product name.",
          fieldName: "productName",
          required: true,
          requiredErrorMessage: "You must provide a product name.",
          maxLength: 200,
          type: "textInput",
        },
        {
          id: "valueProp",
          title: "Value Proposition",
          prompt: "Your product value proposition.",
          fieldName: "valueProp",
          required: true,
          requiredErrorMessage: "You must provide a value proposition.",
          maxLength: 2000,
          type: "textInput",
        },

        {
          id: "featureNameAndDescription",
          title: "Feature",
          prompt: "Enter Feature Name and Description.",
          fieldName: "featureNameAndDescription",
          required: true,
          requiredErrorMessage: "You must enter feature details.",
          maxLength: 2000,
          type: "textInput",
          tooltip:
            "Include the name a description of the feature that you want to announce in this email.",
        },
        {
          id: "problem",
          title: "Problem Feature Solves",
          prompt: "Enter the problem this feature solves.",
          fieldName: "problem",
          required: false,
          maxLength: 1000,
          type: "textInput",
        },
        defaultFieldTypes.additionalInfo,
        {
          id: "cta",
          title: "Call to Action",
          prompt: "Enter a call to action.",
          fieldName: "cta",
          required: true,
          requiredErrorMessage: "You must select a call to action.",
          type: "textInput",
          maxLength: 400,
          presetOptions: true,
          options: {
            value: "Book a demo",
            editable: true,
            options: [
            { title: "Book a demo", content: "Reply now to book a demo" },
            { title: "Invite to an event", content: "Please join us at our event [details]"},
            { title: "Visit a website", content: "Visit [insert website]" },
            { title: "View a case study", content: "Read our case study on [topic]"},
            { title: "View a blog post", content: "View a blog post" },
            { title: "Download a resource", content: "Download a resource" },
            { title: "Start a trial", content: "Start a free trial" },
            { title: "Enter custom CTA", content: "" },
          ],
        }
        },
      ],
    },
    {
      id: "emailInstructions",
      title: "Email Instructions",
      fields: [
        {
          id: "emailLength",
          title: "Email Length",
          prompt: "Email body length.",
          fieldName: "emailLength",
          required: true,
          type: "singleSelect",
          default: "100-150 words",
          
          choices: [
            { value: "Less than 100 words", label: "Less than 100 words" },
            { value: "100-150 words", label: "Between 100-150 words" },
            { value: "150 or more words", label: "150 or more words" },
          ],
        },
        {
          id: "tone",
          title: "Tone",
          prompt: "Tone of the email.",
          fieldName: "tone",
          required: true,
          maxLength: 200,
          requiredErrorMessage: "You must enter a tone.",
          type: "textInput",
        },
      ],
    },
  ],
};
