import { Popover } from "@headlessui/react";
import { BoltIcon } from "@heroicons/react/24/solid";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import Tooltip from "./Tooltip";

export default function AssetWizardSingleSelect(props) {
  const {
    fieldName,
    choices,
    register,
    errors,
    label,
    placeholder,
    isRequired,
    maxLength,
    minLength,
    type,
    defaultValue,
    autoComplete,
    noLabel,
    setValue,
    tooltipContent,
  } = props;


  //Get field choices if they exist
  const fieldOptions = choices[fieldName] ? choices[fieldName].choices : [];

  //Should we disable the field?
  const fieldDisabled = choices[fieldName]
    ? !choices[fieldName].editable
    : false;

  return (
    <div className="m-1">
      <div className="flex flex-row">
        {!noLabel && (
          <label className="text-sm font-medium text-gray-700">{label}</label>
        )}
        <div className="ml-1">
          <Tooltip content={tooltipContent} />
        </div>
      </div>

      <div
        className={`p-0 relative flex ${
          errors[fieldName]
            ? "formTextField-error"
            : "block w-full appearance-none rounded-md border border-gray-300 placeholder-gray-400 shadow-sm focus-within:border-blue-500 focus-within:border-2 focus-within:m-[-1px]  focus-within:ring-blue-500 sm:text-sm"
        } ${fieldDisabled ? "bg-gray-100" : "bg-white"}`}
      >
        <select
          id={fieldName}
          placeholder={placeholder}
          name={fieldName}
          type={type}
          autoComplete={autoComplete}
          disabled={fieldDisabled}
          required={isRequired}
          {...register(fieldName, {
            required: {
              value: isRequired,
              message: "Required",
            },
          })}
          className={`resize-none py-1 px-0 m-1 w-full text-sm bg-transparent border-none outline-none border-transparent focus:border-transparent focus:ring-0`}
        >

          {choices.map((choice) => (
            <option key={choice.id} value={choice.value}>
              {choice.label}
            </option>
          ))
          }
        </select>
      </div>
      {errors[fieldName] && (
        <p className="mt-2 text-sm text-red-600">{errors[fieldName].message}</p>
      )}
    </div>
  );
}
