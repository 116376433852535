import { PlusIcon } from "@heroicons/react/24/outline";

export default function EmptyStateButton(props) {
  const { shrink, intercomTag} = props;
  return shrink ? (
    <button
      type="button"
      id={intercomTag}
      onClick={props.action}
      className="relative -ml-px items-center cursor-pointer flex justify-center rounded-md border-dashed border border-gray-300 bg-white px-4 py-4 text-sm font-medium text-gray-900 hover:bg-blue-50 focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600"
    >
      <PlusIcon className="mr-2.5 h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" />
      <span>{props.title}</span>
    </button>
  ) : (
    <button
      type="button"
      id={intercomTag}
      onClick={props.action}
      className={`${
        shrink && "flex"
      } relative w-full rounded-lg cursor-pointer border-2 border-dashed border-gray-300 p-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
    >
      <div
        className={`mx-auto ${shrink ? "h-6 w-6" : "h-12 w-12"} text-gray-600`}
      >
        {props.icon}
      </div>
      <span className="mt-2 block text-sm font-medium text-gray-900">
        {props.title}
      </span>
    </button>
  );
}
