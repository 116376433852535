import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch } from "react-redux";
import { savePlaybookModuleContent } from "../../../../../../../utils/redux/actions/dataActions/playbookActions";
import { get } from "lodash";

export default function Tiny(props) {
  const { playbookSection, handleClose } = props;

  let defaultContent = get(playbookSection, "content.richText", "");

  const editorRef = useRef(null);
  const dispatch = useDispatch();

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const handleSave = () => {
    let content = editorRef.current.getContent();
    let constructedSection = {
      ...playbookSection,
      content: {
        richText: content,
      },
    };
    dispatch(savePlaybookModuleContent(constructedSection));
    handleClose();
  };

  return (
    <>
    {/* TODO: Add Autosave */}
      <Editor
        apiKey="yo1kjdgnv2opj8sf3u9s7f8hhgquli8sfljw7xjlmvkxdnj7"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={defaultContent}
        onBlur={handleSave}
        init={{
          selector: "textarea",
          skin: "tinymce-5",
          height: 500,
          branding: false,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "table | link image media | code | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      {/* <button onClick={handleSave}>Save Changes</button> */}
    </>
  );
}
