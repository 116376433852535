import { getFunctions, httpsCallable } from "firebase/functions";
import { useId } from "react";
import { functions } from "../../../firebase";
import { selectedProductData } from "../dataActions";

export const getPositioningForm = (productId) => async (dispatch) => {
  const getPositioningFormFunction = httpsCallable(functions, "getPositioningForm");
  const makeRequest = await getPositioningFormFunction({
    productId: productId,
  });

  if (makeRequest.data.success) {
    return makeRequest.data;
  } else {
    console.log("fail");
    console.log(makeRequest.data);
    return false
  }
};




export const updateBenefitsFeaturesMatrix = (data) => async (dispatch) => {
  const updateSectionOrder = dispatch({
    type: "UPDATE_BENEFITS_FEATURES_MATRIX",
    payload: data,
  });
  return updateSectionOrder
};


export const addModule = (type, title, playbookId, productId, organizationId) => async (dispatch) => {

  const uniqueId = Math.random().toString(36).substr(2, 9);

  let section = {
    playbookId: playbookId,
    productId: productId,
    organizationId: organizationId,
    id: uniqueId,
    title: `New ${title}`,
    type: type,
    description: "This is a description of the section",
  }

  
  const addModule = await dispatch({
    type: "ADD_MODULE",
    payload: section,
  });

  const element = document.getElementById(uniqueId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }

  return addModule
}


// Save Positioining TODO: Add success confirmation in the component
export const savePositioning = (data) => async (dispatch) => {
  const savePositioningFunction = httpsCallable(functions, "savePositioning");

  const createRequest = await savePositioningFunction(data);

  if (createRequest.data.success) {

    // Refresh product TODO: this is very data heavy, need to find a better way to do this. Return the updated product from the function?
    const getProduct = await dispatch(
      selectedProductData({
        productId: data.productId,
        organizationId: data.organizationId,
      })
    );

  } else {
    console.log("fail");
    console.log(createRequest.data);
  }
  return createRequest.data;
};


//TODO: I don't think this is used anymore
export const removeProofPoint = (proofpointId, benefitId, productId) => async (dispatch) => {

  let payload = {
    proofpointId: proofpointId,
    benefitId: benefitId,
    productId: productId,
  }

  const removeProofPoint = await dispatch({
    type: "REMOVE_PROOF_POINT",
    payload: payload,
  });
  return removeProofPoint
}



