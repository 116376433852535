import React from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import GlobalLogoV2 from "../../assets/brand/GlobalLogoV2";
import GlobalIcon from "../../assets/brand/GlobaIcon";

export default function SuspenseLoaderInline() {
  return (
    <div className="left-0 top-0 w-full h-full text-center align-middle grid place-items-center">
      
        <div className="squareContainer">
          <div className="square one green"></div>
          <div className="square two green"></div>
          <div className="square three green"></div>
          <div className="square two mid"></div>
          <div className="square three mid"></div>
          <div className="square four mid"></div>
          <div className="square three purple"></div>
          <div className="square four purple"></div>
          <div className="square five purple"></div>
        </div>
      
    </div>
  );
}
