// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";

// Conditional Firebase Configs (use staging config if not on production) TODO: evaluate what this does for emulators.
let firebaseConfig = {
  apiKey: "AIzaSyC8fPM60qw6tyg9bgNlIf_pi5zA0CnKzjY",
  authDomain: "staging-flight-ai.firebaseapp.com",
  projectId: "staging-flight-ai",
  storageBucket: "staging-flight-ai.appspot.com",
  messagingSenderId: "258895547743",
  appId: "1:258895547743:web:ea577a4621c24cf72f3aea"
}

// Conditionally switch firebaseConfig to production configuration
if (window.location.hostname === 'portal.getflight.ai'){
  firebaseConfig = {
    apiKey: "AIzaSyCWYZtzAFTCO_Fd8WcfASpltAxoqZ9ZesQ",
    authDomain: "get-flight-ai.firebaseapp.com",
    projectId: "get-flight-ai",
    storageBucket: "get-flight-ai.appspot.com",
    messagingSenderId: "656629946609",
    appId: "1:656629946609:web:183f0a409f7dbf60067150",
    measurementId: "G-DM8RBV3D9L"
  };
}


// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);


// Check if the app should use emulators / dev / production
export const functions = getFunctions(firebase)
process.env.NODE_ENV === 'development' && connectFunctionsEmulator(functions, "localhost", 5001);

const auth = getAuth();
process.env.NODE_ENV === 'development' && connectAuthEmulator(auth, "http://localhost:9099");

const db = getFirestore(firebase);
process.env.NODE_ENV === 'development' && connectFirestoreEmulator(db, "localhost", 8080);

const storage = getStorage(firebase);
process.env.NODE_ENV === 'development' && connectStorageEmulator(storage, "127.0.0.1", 9199)

export { db, auth, analytics, storage }

export default firebase