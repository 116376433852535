import React from "react";
import WizardAnimation from "../WizardAnimation";
import WizardTableConfirmationIllustration from "./confirmationIllustrations/WizardTableConfirmationIllustration";
import {
  WizardStepTitle,
  WizardStepSubtitle,
  WizardStepBody,
  WizardStepPrompt,
  WizardStepIcon,
  WizardContentContainer,
  WizardActionPanel,
  WizardActionButton,
  WizardChipCloud,
  WizardCenteredRow,
  WizardTitleContainer,
  WizardTitleIcon,
  WizardTitleTitle,
} from "./TailwindComponents";
import InlineLoader from "../../../../../../ux/InlineLoader";

export default function WizardConfirmationTemplate(props) {
  const { fieldSet, handleComplete, form } = props;

  let [loading, setLoading] = React.useState(false);

  const startOpenAICall = () => {
    handleComplete();
    setLoading(true);
  };

  const illustrationSwitch = (illustration) => {
    switch (illustration) {
      case "table":
        return <WizardTableConfirmationIllustration />;
      default:
        return "Couldn't find illustration";
    }
  };

  return (
    <>
      <WizardContentContainer>
        {!loading ? (
          <WizardTitleContainer>
            <WizardCenteredRow>
              <WizardTitleIcon className={fieldSet.color}>
                <fieldSet.icon className="text-white" />
              </WizardTitleIcon>
            </WizardCenteredRow>
            <WizardTitleTitle>{fieldSet.title}</WizardTitleTitle>
            <WizardStepSubtitle>{fieldSet.subtitle}</WizardStepSubtitle>
            <WizardStepBody>{fieldSet.content}</WizardStepBody>
          </WizardTitleContainer>
        ) : (
          <WizardTitleContainer>
            <InlineLoader />
            <WizardTitleTitle>Generating...</WizardTitleTitle>
          </WizardTitleContainer>
        )}
      </WizardContentContainer>
      <WizardActionPanel>
        <WizardActionButton
          disabled={loading}
          className={`${loading && "opacity-25"}`}
          onClick={startOpenAICall}
        >
          Generate Content
        </WizardActionButton>
      </WizardActionPanel>
    </>
  );
}
