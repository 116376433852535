// useNavBarTitle.js
import { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux';

function useNavBarTitle(preTitle, title, subTitle, sticky, stickyOffset, prevailOnUnmount = false) {

  const defaultTitle = useRef(document.title);
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = title;

    dispatch({
      type: "SET_NAV_BAR",
      payload: {
        preTitle: preTitle,
        title: title,
        subTitle: subTitle,
        sticky: sticky,
        stickyOffset: stickyOffset,
      },
    })
  });

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  })
}

export default useNavBarTitle