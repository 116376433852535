import { Bars3Icon, TrashIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import tw from "tailwind-styled-components";
import InlineCartTitleEditor from "../../../../ux/InlineCardTitleEditor";
import InlineFeatureTextEditor from "../../../../ux/InlineFeatureTextEditor";

const Feature = tw.div`
group
flex
flex-col
    border
    text-sm
    border-gray-300
    rounded-md
    p-2
    ${(props) => (props.isDragging ? "bg-gray-200" : "bg-white")}
    `;

const FeatureName = tw.div`
    text-gray-900
    flex-1
    font-medium
    `;
const FeatureContent = tw.div`
    text-gray-700
    `;

const DraggableSectionHandle = tw.div`
    h-5
    w-5
    text-gray-400
`;

const Actions = tw.div`
    flex
    justify-end
    gap-3
    pl-3
    opacity-0
    group-hover:opacity-100
    transition
    duration-200
    ease-in-out
  `;

    const Action = tw.div`
    h-5
    w-5
    text-gray-400
    cursor-pointer
`;

const FeatureTitleBar = tw.div`
    text-md
    text-gray-900
    font-medium
    text-gray-900
    flex  
    justify-between
    `;


export default function FeatureItem(props) {
  const { feature, index, removeFeature, benefitId, onEnd } = props;
  return (
    <Draggable draggableId={feature.id} index={index}>
      {(provided, snapshot) => (
        <Feature
          ref={provided.innerRef}
          {...provided.draggableProps}
          isDragging={snapshot.isDragging}
        >

          <FeatureTitleBar>

          <FeatureName>
            <InlineFeatureTextEditor
                defaultText={feature.name}
                parent={benefitId}
                onEnd={(tar, ev, par ) => onEnd(tar, ev, par)}
                target={feature.id}
                classes={"text-sm"}
                name="name"
              />
          </FeatureName>
          
          <Actions>
            <Action onClick={() => removeFeature(feature.id, benefitId)}>
              <TrashIcon />
            </Action>

            <DraggableSectionHandle {...provided.dragHandleProps}>
              <Bars3Icon />
            </DraggableSectionHandle>
          </Actions>
          </FeatureTitleBar>

          <FeatureContent>
          <InlineFeatureTextEditor
                defaultText={feature.content}
                parent={benefitId}
                onEnd={(tar, ev, par ) => onEnd(tar, ev, par)}
                target={feature.id}
                classes={"text-sm"}
                name="content"
              />
            </FeatureContent>

        </Feature>
      )}
    </Draggable>
  );
}
