import { getAuth, signOut, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import firebase from "../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { handleError } from "./utils/handleError";
const functions = getFunctions();

// AUTHENTICATION FUNCTIONS
const auth = getAuth(firebase);

// Register new user TODO: Add success confirmation in the component
export const registerAction = (data) => async (dispatch) => {
  const register = httpsCallable(functions, "register");
  const registerRequest = await register(data);
  return registerRequest.data;
};

// Login user
export const loginAction = (data) => async (dispatch) => {
  try {
    const loginRequest = await signInWithEmailAndPassword(
      auth,
      data.email,
      data.password
    );
  } catch (error) {
    return {
      success: false,
      error: error
    }
  }
};

// Check user login type
export const checkUserLoginType = (data) => async (dispatch) => {
  const checkUserLoginTypeFunction = httpsCallable(functions, "checkUserLoginType");
  const checkUserLoginTypeRequest = await checkUserLoginTypeFunction(data);
  return checkUserLoginTypeRequest.data;
};


// Password Reset
export const passwordResetAction = (data) => async (dispatch) => {
  try {
    const passwordResetRequest = await sendPasswordResetEmail(
      auth,
      data.email,
    );
    console.log(passwordResetRequest)
    return {
      success: true,
      message: passwordResetRequest
    }
  } catch (error) {
    return {
      success: false,
      message: error.code
    }
  }
};

//Log Out
export const logOut = () => (dispatch) => {
  signOut(auth)
    .then(() => {
      dispatch({ type: "SET_UNAUTHENTICATED" });
      return { success: true };
    })
    .catch((error) => {
      // An error happened.
    });
};

//Get User Data
export const getUserData = () => async (dispatch) => {
  dispatch({ type: "SET_FULL_SCREEN_LOADING" });

  // Callable Functions
  const user = httpsCallable(functions, "getUserData");

  try {
    const getUser = await user();
    if (getUser.data.success) {
      dispatch({ type: "SET_AUTHENTICATED", payload: getUser.data });
      dispatch({ type: "SET_AUTH_STATE_KNOWN" });
    } else {
      signOut(auth)
      dispatch({ type: "SET_UNAUTHENTICATED"});
    }
  } catch (err) {
    console.log(err);
  }

  dispatch({ type: "STOP_FULL_SCREEN_LOADING" });
};

// Verify User Email
export const verifyUserEmail = (data) => async (dispatch) => {
  const verifyUserEmail = httpsCallable(functions, "verifyUserEmail");
  const verifyRequest = await verifyUserEmail(data);
  console.log(verifyRequest.data)
  return verifyRequest.data;
};  

// User Action Code
export const userActionCode = (data) => async (dispatch) => {
  const userActionCodeFunction = httpsCallable(functions, "userActionCode");
  const request = await userActionCodeFunction(data);
  return request.data;
};  


// Resend Verification Email
export const resendVerificationEmail = () => async (dispatch) => {
  const resendVerificationEmail = httpsCallable(functions, "resendVerificationEmail");
  const resendRequest = await resendVerificationEmail();
  return resendRequest.data;
}