import { useSelector } from "react-redux";
import GlobalLogo from "../../assets/brand/GlobalLogo";
import LoginForm from "../../components/authentication/LoginForm";
import LoginHeader from "../../components/authentication/LoginHeader";
import { get } from "lodash";

export default function LoginV2() {
  const ui = useSelector((state) => state.ui);
  let loader = get(ui, "fullScreenLoading");
  return !loader && (
    <>
      <div className="flex min-h-full min-h-screen">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <LoginHeader />      
            <LoginForm />
          </div>
        </div>
        <div className="relative bg-gradient-to-tr from-green-300 via-blue-500 to-blue-900 hidden w-0 flex-1 lg:block"></div>
      </div>
    </>
  );
}
