import { getFunctions, httpsCallable } from "firebase/functions";
import { useId } from "react";
import { useIntercom } from "react-use-intercom";
import { selectedProductData } from "../dataActions";
import { useNavigate } from "react-router-dom";
const functions = getFunctions();

// Create Playbook TODO: Add success confirmation in the component
export const quickStartExtractMessaging = (data) => async (dispatch) => {
  try {
    const quickStartExtractMessagingFunction = httpsCallable(functions, "quickStartExtractMessaging");
    const createRequest = await quickStartExtractMessagingFunction(data);
    if (createRequest.data.success) {
      dispatch({ type: "NOTEBOOK/SET_ANALYZED_DATA_FOR_REVIEW", payload: {organizationId: data.organizationId, productId: data.productId, ...createRequest.data} });
    } else {
    }
  } catch (err) {
    console.log(err);
  }

  return
};

// Save new positioning
export const saveRevisedPositioning = (data) => async (dispatch) => {
  const savePositioningFunction = httpsCallable(functions, "savePositioning");
  const createRequest = await savePositioningFunction(data);
  if (createRequest.data.success) {
    dispatch({ type: "SHOW_NOTIFICATION", payload: {title: "Notebook Updated", message: "We updated your positioning in your product notebook.", type: "static", meta: {}} });
    // Refresh product TODO: this is very data heavy, need to find a better way to do this. Return the updated product from the function?
    const getProduct = await dispatch(
      selectedProductData({
        productId: data.productId,
        organizationId: data.organizationId,
      })
    );

  } else {
    console.log("fail");
    console.log(createRequest.data);
  }
  return createRequest.data;
};

// Insert uploaded document into positioning document editor
export const insertDocumentIntoPositioningEditor = (data) => async (dispatch) => {
  dispatch({ type: "NOTEBOOK/SET_DOCUMENT_DATA_FOR_EDITING", payload: data });
  console.log('complete: ', data)
  return 
};