import React, { Fragment } from "react";
import { Controller, useController } from "react-hook-form";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import Tooltip from "./Tooltip";

export default function AssetWizardSizeMultiSelect(props) {
  const {
    fieldName,
    register,
    noLabel,
    label,
    errors,
    placeholder,
    options,
    required,
    maxLength,
    minLength,
    type,
    autoComplete,
    choices,
    columns,
    tooltipContent,
    watch,
    defaultValue,
    control,
  } = props;

  // Determine if we should disable editing
  const editable = options ? options.editable : true;

  const handleChange = (onChange, e, value) => {
    if (editable) {
      let selectedOptions = value;

      if (selectedOptions.includes(e)) {
        selectedOptions = selectedOptions.filter((item) => item !== e);
      } else {
        selectedOptions.push(e);
      }

      // If "all" is selected, remove all other choices
      if (e === defaultValue) {
        selectedOptions = [defaultValue];
      }

      // If an option is selected that is not "all", remove "all"
      if (e !== defaultValue && selectedOptions.includes(defaultValue)) {
        selectedOptions = selectedOptions.filter(
          (item) => item !== defaultValue
        );
      }

      // If no choices are selected, default to "all"
      if (selectedOptions.length === 0) {
        selectedOptions = [defaultValue];
      }

      // If all choices are selected, default to "all"
      if (selectedOptions.length === choices.length - 1) {
        selectedOptions = [defaultValue];
      }

      return onChange(selectedOptions);
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const Option = ({ active, onClick, label }) => (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        active
          ? "bg-blue-800 border-transparent text-white hover:bg-blue-800 hover:text-white"
          : "text-gray-600 bg-gray-100 hover:bg-blue-200",
          editable ? "cursor-pointer" : "cursor-not-allowed",
        "inline-flex items-center px-2.5 py-1 rounded-full text-sm font-medium m-1"
      )}
    >
      {label}
    </button>
  );

  return (
    <>
      <div className="m-1">

        <div className="flex flex-ro">
          {!noLabel && (
            <label className="text-sm font-medium text-gray-700 mb-2">
              {label} {required && <span className="text-red-500">*</span>} {!editable && <span className="ml-1 text-xs text-gray-500">(Locked to selected playbook)</span>}
            </label>
          )}
          <div className="ml-1">
            <Tooltip content={tooltipContent} />
          </div>
        </div>

        <div className="flex justify-evenly">
          <Controller
            defaultValue={[defaultValue]}
            control={control}
            name={fieldName}
            rules={{ required: { value: required, message: "Required" } }}
            render={({ field: { value, onChange } }) =>
              choices.map((option, index) => (
                <Option
                  key={index}
                  label={option.label}
                  active={value.includes(option.value)}
                  onClick={() => handleChange(onChange, option.value, value)}
                />
              ))
            }
          />
        </div>

        {errors[fieldName] && (
          <p className="mt-2 text-sm text-red-600">
            {errors[fieldName].message}
          </p>
        )}
      </div>
    </>
  );
}
