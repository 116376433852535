import React, { lazy, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { get } from "lodash";
import { useIntercom } from "react-use-intercom";

import FullScreenLoader from "../components/ux/FullScreenLoader";

import SuspenseLoaderLinear from "../components/ux/SuspenseLoaderLinear";
import SuspenseLoaderInline from "../components/ux/SuspenseLoaderInline";
import { ProtectedRoute } from "./ProtectedRoute";
import AssetEditor from "../pages/dashboard/product/assets/AssetEditor";
import QuickStartEditor from "../components/product/notebook/quickStart/QuickStartEditor";
import NotebookShell from "../pages/dashboard/product/notebook/NotebookShell";
import QuickStartReview from "../components/product/notebook/quickStart/QuickStartReview";


const Notebook = lazy(() =>
  import("../components/product/positioning/v2Form/Notebook")
);

const CreateInviteCodes = lazy(() =>
  import("../pages/admin/CreateInviteCodes")
);

const SharedAppShell = lazy(() => import("../pages/navigation/SharedAppShell"));
const SharedRoute = lazy(() => import("./router/SharedRoute"));
const SharedAsset = lazy(() => import("../pages/shared/SharedAsset"));
const SharedPlaybook = lazy(() => import("../pages/shared/SharedPlaybook"));

const PermissionedRoute = lazy(() => import("./router/PermissionedRoute"));
const FloatingFunctionTrigger = lazy(() =>
  import("../components/dev/FloatingFunctionTrigger")
);
const AuthenticatedAppShell = lazy(() =>
  import("../pages/navigation/AuthenticatedAppShell")
);
const LoginV2 = lazy(() => import("../pages/authentication/LoginV2"));
const Register = lazy(() => import("../pages/authentication/Register"));
const Error404 = lazy(() => import("../pages/navigation/Error404"));
const PasswordReset = lazy(() =>
  import("../pages/authentication/PasswordReset")
);
const ProductOverview = lazy(() =>
  import("../pages/dashboard/product/ProductOverview")
);
const Organizations = lazy(() => import("../pages/ogranization/organizations"));
const Home = lazy(() => import("../pages/home/Home"));
const AppShell = lazy(() => import("../pages/navigation/AppShell"));
const OrganizationHome = lazy(() =>
  import("../pages/ogranization/OrganizationHome")
);
const PositioningShell = lazy(() =>
  import("../pages/dashboard/product/positioning/PositioningShell")
);
const QuickStart = lazy(() =>
  import("../components/product/positioning/forms/QuickStart")
);
const EditPositioning = lazy(() =>
  import("../components/product/positioning/forms/EditPositioning")
);
const ViewPositioning = lazy(() =>
  import("../components/product/positioning/ViewPositioning")
);
const SalesPlaybooksWidget = lazy(() =>
  import("../components/product/playbooks/SalesPlaybooksWidget")
);
const PlaybookEditor = lazy(() =>
  import("../pages/dashboard/product/playbook/PlaybookEditor")
);
const BasicPageTemplate = lazy(() =>
  import("../components/ux/BasicPageTemplate")
);
const Action = lazy(() => import("../pages/navigation/Action"));
const SidebarAppShell = lazy(() =>
  import("../pages/dashboard/product/SidebarAppShell")
);
const PlaybookDashboard = lazy(() =>
  import("../pages/dashboard/product/playbook/PlaybookDashboard")
);
const Playbooks = lazy(() =>
  import("../pages/dashboard/product/playbook/Playbooks")
);
const ProductSettings = lazy(() =>
  import("../pages/dashboard/product/ProductSettings")
);
const ComingSoon = lazy(() => import("../pages/comingsoon/ComingSoon"));
const AssetDetails = lazy(() =>
  import("../pages/dashboard/product/assets/AssetDetails")
);
const Assets = lazy(() => import("../pages/dashboard/product/assets/Assets"));

const OrganizationSettings = lazy(() =>
  import("../pages/ogranization/OrganizationSettings")
);

export default function AppRoutes() {
  const { boot, update } = useIntercom();

  const user = useSelector((state) => state.user);
  let authenticated = get(user, "authenticated");

  function RequireAuth({ children }) {
    return authenticated ? children : <LoginV2 />;
  }

  function NoAuth({ children }) {
    return authenticated ? <Navigate to="/" /> : children;
  }

  // Only boot intercom if user is authenticated

  const intercomBootCredentials = {
    name: `${user.credentials.firstName} ${user.credentials.lastName}`,
    email: `${user.credentials.email}`,
    createdAt: `${user.credentials.createdAt}`,
    userId: `${user.credentials.intercomExternalId}`,
    userHash: `${user.credentials.intercomHash}`,
  };

  useEffect(() => {
    if (authenticated) {
      boot(intercomBootCredentials);
    }
  }, [authenticated]);

  // Ping intercom everytime the url changes
  let navigate = useNavigate();
  useEffect(() => {
    if (authenticated) {
      update();
    }
  }, [navigate]);

  return (
    <Suspense fallback={<FullScreenLoader />}>
      <Routes>
        {/* Global Routes */}
        <Route path="*" element={<Error404 />} />
        <Route path="/action/:actionCode" element={ <Suspense fallback={<SuspenseLoaderLinear />}> <Action /> </Suspense> } />

        <Route path="/comingsoon" element={ <Suspense fallback={<SuspenseLoaderLinear />}> <ComingSoon /> </Suspense> } />

        {/* SHARED LINKS */}
        <Route path="/" element={ <SharedRoute> {" "} <SharedAppShell />{" "} </SharedRoute> } >
          <Route path="a/:assetId" element={<SharedAsset />} />
          <Route path="p/:playbookId" element={<SharedPlaybook />} />
        </Route>

        {/* Unprotected Routes */}
        <Route path="/" element={ <Suspense fallback={<SuspenseLoaderLinear />}> {" "} <NoAuth> {" "} <AppShell />{" "} </NoAuth>{" "} </Suspense> } >
          <Route path="/login" element={ <Suspense fallback={<SuspenseLoaderLinear />}> {" "} <LoginV2 />{" "} </Suspense> } />
          <Route path="/register" element={ <Suspense fallback={<SuspenseLoaderLinear />}> {" "} <Register />{" "} </Suspense> } />
          <Route path="/passwordreset" element={ <Suspense fallback={<SuspenseLoaderLinear />}> {" "} <PasswordReset />{" "} </Suspense> } />
        </Route>

        {/* Protected Routes */}
        <Route path="/" element={ <ProtectedRoute> {" "} <AuthenticatedAppShell />{" "} </ProtectedRoute> } >
          <Route index element={ <Suspense fallback={<SuspenseLoaderInline />}> {" "} <Home />{" "} </Suspense> } />
          <Route path="/organizations" element={ <Suspense fallback={<SuspenseLoaderInline />}> {" "} <Organizations />{" "} </Suspense> } />
          <Route path="/settings" element={ <Suspense fallback={<SuspenseLoaderInline />}> <Register /> </Suspense> } />


<Route path="/invite" element={ <Suspense fallback={<SuspenseLoaderLinear />}> <CreateInviteCodes /> </Suspense> } />

          {/* PRODUCT ROUTES*/}
          <Route path="/:organizationId/" element={ <PermissionedRoute> {" "} <SidebarAppShell />{" "} </PermissionedRoute> } >
            <Route index element={ <Suspense fallback={<SuspenseLoaderInline />}> <OrganizationHome /> </Suspense> } />
            <Route path="/:organizationId/settings" element={ <Suspense fallback={<SuspenseLoaderInline />}> <OrganizationSettings /> </Suspense> } />

            {/* PRODUCT OVERVIEW */}
            <Route path="/:organizationId/:productId" element={ <Suspense fallback={<SuspenseLoaderInline />}> <ProductOverview /> </Suspense> } />
            
            {/* PRODUCT SETTINGS */}
            <Route
              path="/:organizationId/:productId/settings"
              element={
                <Suspense fallback={<SuspenseLoaderInline />}>
                  <ProductSettings />
                </Suspense>
              }
            />
            {/* POSTIONING */}
            <Route
              path="/:organizationId/:productId/positioning"
              element={
                <Suspense fallback={<SuspenseLoaderInline />}>
                  <PositioningShell />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<SuspenseLoaderInline />}>
                    <ViewPositioning />
                  </Suspense>
                }
              />
              <Route
                path="/:organizationId/:productId/positioning/quickstart"
                element={
                  <Suspense fallback={<SuspenseLoaderInline />}>
                    <QuickStart />
                  </Suspense>
                }
              />
              <Route
                path="/:organizationId/:productId/positioning/edit"
                element={
                  <Suspense fallback={<SuspenseLoaderInline />}>
                    <EditPositioning />
                  </Suspense>
                }
              />
            </Route>


            {/* NOTEBOOK */}

              <Route
                path="/:organizationId/:productId/notebook"
                element={
                  <Suspense fallback={<SuspenseLoaderInline />}>
                    <Notebook />
                  </Suspense>
                }
              />
              <Route
                path="/:organizationId/:productId/notebook/quickstart"
                element={
                  <Suspense fallback={<SuspenseLoaderInline />}>
                    <QuickStartEditor />
                  </Suspense>
                }
              />
                            <Route
                path="/:organizationId/:productId/notebook/review"
                element={
                  <Suspense fallback={<SuspenseLoaderInline />}>
                    <QuickStartReview />
                  </Suspense>
                }
              />
              <Route
                path="/:organizationId/:productId/notebook/edit"
                element={
                  <Suspense fallback={<SuspenseLoaderInline />}>
                    <EditPositioning />
                  </Suspense>
                }
              />
              <Route
                path="/:organizationId/:productId/notebook/notebook"
                element={
                  <Suspense fallback={<SuspenseLoaderInline />}>
                    <Notebook />
                  </Suspense>
                }
              />
            




            {/* DEV */}

            {/* PLAYBOOKS */}
            <Route
              path="/:organizationId/:productId/playbook"
              element={
                <Suspense fallback={<SuspenseLoaderInline />}>
                  {" "}
                  <Playbooks />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:organizationId/:productId/playbook/:playbookId/"
              element={
                <Suspense fallback={<SuspenseLoaderInline />}>
                  {" "}
                  <PlaybookDashboard />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:organizationId/:productId/playbook/:playbookId/edit"
              element={
                <Suspense fallback={<SuspenseLoaderInline />}>
                  {" "}
                  <PlaybookEditor />{" "}
                </Suspense>
              }
            />

            {/* ASSETS */}
            <Route
              path="/:organizationId/:productId/assets"
              element={
                <Suspense fallback={<SuspenseLoaderInline />}>
                  {" "}
                  <Assets />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:organizationId/:productId/asset"
              element={
                <Suspense fallback={<SuspenseLoaderInline />}>
                  {" "}
                  <Assets />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:organizationId/:productId/asset/:assetId/"
              element={
                <Suspense fallback={<SuspenseLoaderInline />}>
                  {" "}
                  <AssetDetails />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:organizationId/:productId/asset/:assetId/edit"
              element={
                <Suspense fallback={<SuspenseLoaderInline />}>
                  {" "}
                  <AssetEditor />{" "}
                </Suspense>
              }
            />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}
