import {
  CalendarIcon,
  CodeBracketIcon,
  DocumentIcon,
  ExclamationCircleIcon,
  LinkIcon,
  PencilSquareIcon,
  PhotoIcon,
  TableCellsIcon,
  VideoCameraIcon,
  ViewColumnsIcon,
  Bars4Icon,
  Bars3BottomLeftIcon,
  CircleStackIcon,
  ArrowTrendingUpIcon,
  NewspaperIcon,
  GlobeAltIcon,
  UserIcon,
  PuzzlePieceIcon,
  LightBulbIcon,
  NoSymbolIcon,
  ServerStackIcon,
  BookOpenIcon,
  ChartPieIcon,
  ExclamationTriangleIcon,
  ClipboardDocumentCheckIcon,
  ChatBubbleLeftIcon,
  BuildingLibraryIcon,
  AtSymbolIcon,
} from "@heroicons/react/24/outline";

export const assetTypes = [
  // {
  //   id: 0,
  //   name: "Blank Document",
  //   type: "blank",
  //   description: "Add a blank document with text, images, and tables.",
  //   url: "#",
  //   color: "bg-green-500",
  //   icon: PencilSquareIcon,
  //   intercomTag: "blanksection-sectionname-playbook",
  // },
  {
    id: 2,
    name: "Cold Outreach Email",
    type: "coldOutreachEmail",
    description: "Write an email to introduce prospective customers to your product.",
    url: "#",
    color: "blue-500",
    icon: AtSymbolIcon,
    intercomTag: "overview-sectionname-playbook",
  },
  {
    id: 3,
    name: "Feature Announcement Email",
    type: "featureAnnouncementEmail",
    description: "Write an email to introduce a new product feature to customers.",
    url: "#",
    color: "teal-500",
    icon: AtSymbolIcon,
    intercomTag: "wiifm-sectionname-playbook",
  },
  {
    id: 4,
    name: "Product One Pager",
    type: "productOnepagerOverview",
    description: "Create a product one-pager for a specific target audience.",
    url: "#",
    color: "amber-500",
    icon: DocumentIcon,
    intercomTag: "market-sectionname-playbook",
  },
];
