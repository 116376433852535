import React, { useState } from "react";
import { useForm } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useNavigate } from "react-router-dom";
import {
  DocumentArrowDownIcon,
  DocumentCheckIcon,
} from "@heroicons/react/24/solid";
import WizardStep from "./WizardStep";
import { useDispatch } from "react-redux";
import { completePlaybookModuleWizard } from "../../../../../../utils/redux/actions/dataActions/playbookActions";
import { WizardContainer } from "./templates/TailwindComponents";
import { useIntercom } from "react-use-intercom";


export default function Wizard(props) {
  
  // React Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { trackEvent } = useIntercom();
  const form = useForm();
  const [step, setStep] = useState(0);

  // Desctructure props
  const { wizardFields, functionCase, playbookSection, open, setOpen } = props;
  const { register, handleSubmit, watch, reset, setError, control, formState: { errors }, } = form;

  // Save Action
  const saveAction = async (data) => {
    const req = {
      requestCode: functionCase,
      playbookSection: playbookSection,
      wizardData: data,
    };

    const completeWizard = await dispatch(completePlaybookModuleWizard(req));
    trackEvent("playbook-add-module-wizard-complete", {
      wizardType: functionCase
    });
    setOpen(false)
  };

  // Handle Complete: Wrap Save Action with React Hook Form handleSubmite
  const handleComplete = () => {
    handleSubmit(saveAction)();
  };

  // Prevent form submit
  const preventFormSubmit = (e) => {
    e.preventDefault();
  };

  // Control Wizard Step
  const stepper = (action) => {
    if (action === "next") {
      setStep(step + 1);
    } else if (action === "back") {
      setStep(step - 1);
    } else {
      setStep(action);
    }
  };

  return (
    <WizardContainer>
      <form noValidate id="registration-form" onSubmit={preventFormSubmit}>
        {wizardFields.map((fieldSet, index) => {
          return (
            <WizardStep
              key={index}
              index={index}
              active={index === step}
              step={step}
              fieldSet={fieldSet}
              stepper={stepper}
              form={form}
              handleComplete={handleComplete}
            />
          );
        })}
      </form>
    </WizardContainer>
  );
}
