import { createReducer } from "@reduxjs/toolkit"
import { get } from "lodash";

const initialState = {
    products: {},
    organizations: {},
    playbooks: {},
    assets: {},
    sharedPlaybooks: {},
    notebook: {},
  };



const v2DataReducer = createReducer(initialState, (builder) => {
    builder.addCase('SET_AUTHENTICATED', (state, action) => {
      state.organizations = action.payload.organizations
    })
    builder.addCase('UPDATE_ORGANIZATION', (state, action) => {
        state.organizations[action.payload.organization.organizationId] = {
            loaded: true,
            ...state.organizations[action.payload.organization.organizationId],
            ...action.payload.organization,
        }
        state.products = {
            ...state.products,
            ...action.payload.productDetails.products,
        }
        })
builder.addCase('SET_PENDING_INVITES', (state, action) => {
        state.organizations[action.payload.organizationId] = {
            loaded: true,
            ...state.organizations[action.payload.organizationId],
            pendingInvites: action.payload.responseItems,
        }
    })
    builder.addCase('UPDATE_PENDING_INVITES', (state, action) => {
        state.organizations[action.payload.organizationId] = {
            ...state.organizations[action.payload.organizationId],
            pendingInvites: [
              ...state.organizations[action.payload.organizationId]
                .pendingInvites,
              action.payload,
            ],
          }
    })
    builder.addCase('UPDATE_RBAC', (state, action) => {
        state.organizations[action.payload.organizationId].rbac[action.payload.uid] = action.payload
    })

    // PLAYBOOKS
    builder.addCase('LIVE_UPDATE_PLAYBOOK_EDITING_VERSION', (state, action) => {
        state.playbooks[action.payload.playbookId].editingVersion = action.payload.editingVersion
    })
    builder.addCase('UPDATE_SECTION_ORDER', (state, action) => {
        state.playbooks[action.payload.playbookId].editingVersion.sectionOrder = action.payload.newSectionOrder
    })
    builder.addCase('ADD_MODULE', (state, action) => {
        state.playbooks[action.payload.playbookId].editingVersion.sectionOrder = [
            ...state.playbooks[action.payload.playbookId].editingVersion.sectionOrder,
            action.payload.sectionId
        ]
        state.playbooks[action.payload.playbookId].editingVersion.sections[action.payload.sectionId] = action.payload
    })
    builder.addCase('RESTORE_VERSION', (state, action) => {
        state.playbooks[action.payload.playbookId].editingVersion = action.payload.version
    })
    builder.addCase('RESTORE_MODULE', (state, action) => {
        state.playbooks[action.payload.playbookId].editingVersion.sections[action.payload.sectionId] = action.payload
    })
    builder.addCase('DELETE_MODULE', (state, action) => {
        state.playbooks[action.payload.playbookId].editingVersion.sectionOrder = state.playbooks[action.payload.playbookId].editingVersion.sectionOrder.filter((sectionId) => sectionId !== action.payload.sectionId)
    })
    builder.addCase('COMPLETE_PLAYBOOK_MODULE_INSERT_CONTENT', (state, action) => {
        state.playbooks[action.payload.playbookId].editingVersion.sections[action.payload.sectionId] = action.payload
    })
    builder.addCase('UPDATE_PLAYBOOK_MODULE', (state, action) => {
        state.playbooks[action.payload.playbookId].editingVersion.sections[action.payload.sectionId] = action.payload
    })

    builder.addCase('SAVE_PLAYBOOK_MODULE_CONTENT', (state, action) => {
        state.playbooks[action.payload.playbookId].editingVersion.sections[action.payload.sectionId] = action.payload
    })
    builder.addCase('SET_EDIT_TO_FALSE', (state, action) => {
        state.playbooks[action.payload.playbookId].editingVersion.sections[action.payload.sectionId].edit = false
    })


    
    
    
    builder.addCase('UPDATE_SECTION', (state, action) => {
        state.playbooks[action.payload.playbookId] = {
            ...state.playbooks[action.payload.playbookId],
            sections: {
                ...state.playbooks[action.payload.playbookId].sections,
                [action.payload.sectionId]: action.payload.section,
            },
        }
    })
    builder.addCase('UPDATE_PLAYBOOK', (state, action) => {
        state.playbooks[action.payload.playbookId] = {
            ...state.playbooks[action.payload.playbookId],
            ...action.payload.playbook,
        }
    })

    // PRODUCT
    builder.addCase('SET_SELECTED_PRODUCT', (state, action) => {
        state.products[action.payload.product.productId] = {
                ...state.products[action.payload.product.productId],
                detailedLoad: true,
                ...action.payload.product,
            }
            state.playbooks = {
                ...state.playbooks,
                ...action.payload.playbooks,
            }
            state.assets = {
                ...state.assets,
                ...action.payload.assets,
            }
            state.organizations[action.payload.product.organizationId].products = [
                action.payload.product.productId,
                ...state.organizations[action.payload.product.organizationId].products,
            ]
    })

    // POSITIONING
    builder.addCase('UPDATE_BENEFITS_FEATURES_MATRIX', (state, action) => {
        state.products[action.payload.productId][action.payload.focusVersion].benefitsFeaturesMatrix = action.payload.newState
    })

    // NOTEBOOK
    builder.addCase('NOTEBOOK/SET_ANALYZED_DATA_FOR_REVIEW', (state, action) => {
        state.products[action.payload.productId].notebook = {
            ...state.products[action.payload.productId].notebook,
            ...action.payload.response
        }
    })
    builder.addCase('NOTEBOOK/SET_DOCUMENT_DATA_FOR_EDITING', (state, action) => {
        state.products[action.payload.productId].notebook = {
            ...state.products[action.payload.productId].notebook,
            htmlDocument: action.payload.html
        }
    })

    // ASSETS
    builder.addCase('INSERT_CONTENT_INTO_CURRENT_ASSET', (state, action) => {
        state.assets[action.payload.assetId].editingVersion.content = action.payload.content
    })
    builder.addCase('UPDATE_ASSET', (state, action) => {
        state.assets[action.payload.assetId] = action.payload.asset
    })

    // SHARING
    builder.addCase('SET_SHARED_PLAYBOOK', (state, action) => {
        state.sharedPlaybooks[action.payload.playbookId] = action.payload
    })
    builder.addCase('SET_SHARED_ASSET', (state, action) => {
        state.sharedAssets[action.payload.assetId] = action.payload
    })
    builder.addCase("SET_UNAUTHENTICATED", (state, action) => {
        state = initialState;
    })
  })

  export default v2DataReducer