import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();

// Create Asset TODO: Add success confirmation in the component
export const createAsset = (data) => async (dispatch) => {
  const createAssetFunction = httpsCallable(functions, "createAsset");
  const createRequest = await createAssetFunction(data);
  return createRequest.data;
};

// Create Asset TODO: Add success confirmation in the component
export const getWizardFieldSuggestions = (data) => async (dispatch) => {
  const getWizardFieldSuggestionsFunction = httpsCallable(
    functions,
    "getWizardFieldSuggestions"
  );
  const createRequest = await getWizardFieldSuggestionsFunction(data);
  return createRequest.data;
};

// Call Asset Wizard Function
export const runAssetWizard = (data) => async (dispatch) => {
  try {
    const runAssetWizardFunction = httpsCallable(functions, "runAssetWizard");
    const runRequest = await runAssetWizardFunction(data);

    if (runRequest.data.advancedResponse) {
      dispatch({
        type: "INSERT_CONTENT_INTO_CURRENT_ASSET",
        payload: {
          assetId: data.assetId,
          content: runRequest.data.advancedResponse.htmlFormattedResponse,
        },
      });
      dispatch({
        type: "SHOW_NOTIFICATION",
        payload: {
          title: "How does it look?",
          message: "Flight created this for you using AI.",
          type: "feedback",
          meta: {
            humanLoopDataId: runRequest.data.advancedResponse.humanLoopResponse.humanLoopDataId,
          },
        },
      });
    } else {
      dispatch({
        type: "INSERT_CONTENT_INTO_CURRENT_ASSET",
        payload: {
          assetId: data.assetId,
          content: runRequest.data.html,
        },
      });
    }
    return runRequest.data;
  } catch (error) {
    console.log(error);
    dispatch({
      type: "INSERT_CONTENT_INTO_CURRENT_ASSET",
      payload: {
        assetId: data.assetId,
        content: `There was an error generating the asset. Please try again. Error: ${error}`,
      },
    });
  }
};
// Insert Content from Wizard into Asset
export const insertContentIntoAsset = (data) => async (dispatch) => {
  dispatch({
    type: "INSERT_CONTENT_INTO_CURRENT_ASSET",
    payload: {
      content: data.HTML,
      assetId: data.assetId,
    },
  });
};

// Save Asset
export const saveAsset = (asset) => async (dispatch) => {
  const saveAssetFunction = httpsCallable(functions, "saveAsset");
  const saveRequest = await saveAssetFunction(asset);
  return saveRequest.data;
};
