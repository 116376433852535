const { PencilIcon, PuzzlePieceIcon } = require("@heroicons/react/24/outline");

exports.leaveBehind = {
  overview: {
    title: "New Leave Behind",
    description:
      "Fill in the information below to create an email. Flight will use everything it knows about your positioning, features, and target audience to generate.",
    icon: PuzzlePieceIcon,
    color: "bg-blue-500",
  },
  fieldSet: [
    {
      id: "productDetails",
      title: "Target Audience",
      fields: [
        {
          id: "industries",
          title: "Industry",
          prompt: "Target industry.",
          fieldName: "industries",
          required: true,
          requiredErrorMessage: "You must provide an industry.",
          possibleSuggestions: true,
          type: "textInput",
        },
        {
          id: "usecases",
          title: "Use Cases",
          prompt: "Target use case.",
          fieldName: "usecases",
          required: true,
          requiredErrorMessage: "You must provide a target use case.",
          possibleSuggestions: true,
          type: "textInput",
        },
        {
          id: "size",
          title: "Organization Size",
          prompt: "Target organization size.",
          fieldName: "size",
          required: true,
          requiredErrorMessage: "You must provide a target organization size.",
          possibleSuggestions: true,
          type: "multiSelect",
          options: [
            { value: "all", label: "All" },
            { value: "small", label: "Small" },
            { value: "medium", label: "Medium" },
            { value: "large", label: "Large" },
            { value: "enterprise", label: "Enterprise" },
          ],
        },
      ],
    },
    {
      id: "productDetails",
      title: "Product Details",
      fields: [
        {
          id: "productName",
          title: "Product Name",
          prompt: "Your customer facing product name.",
          fieldName: "productName",
          required: true,
          requiredErrorMessage: "You must provide a product name.",
          possibleSuggestions: true,
          type: "textInput",
        },
        {
          id: "productValueProp",
          title: "Value Proposition",
          prompt: "Your product value proposition.",
          fieldName: "productValueProp",
          required: true,
          requiredErrorMessage: "You must provide a value proposition.",
          possibleSuggestions: true,
          type: "textInput",
        },
        {
          id: "jobTitle",
          title: "Job Title",
          prompt: "Target job title.",
          fieldName: "jobTitle",
          required: true,
          requiredErrorMessage: "You must provide a target job title.",
          possibleSuggestions: true,
          type: "textInput",
        },
        {
          id: "marketTrend",
          title: "Market Trend",
          prompt: "Market trend to include.",
          fieldName: "marketTrend",
          required: false,
          possibleSuggestions: true,
          type: "textInput",
        },
        {
          id: "proofpoint",
          title: "Proof Point",
          prompt: "Proof point to include.",
          fieldName: "proofpoint",
          required: false,
          possibleSuggestions: true,
          type: "textInput",
        },
        {
          id: "keyBenefit",
          title: "Key Benefit",
          prompt: "Key benefit to focus on.",
          fieldName: "keyBenefit",
          possibleSuggestions: true,
          required: false,
          type: "textInput",
        },
        {
          id: "cta",
          title: "Call to Action",
          prompt: "Call to action.",
          fieldName: "cta",
          possibleSuggestions: false,
          required: false,
          type: "textInput",
        },
      ],
    },
    {
      id: "emailDetails",
      title: "Email Details",
      fields: [
        {
          id: "emailLength",
          title: "Email Length",
          prompt: "Email body length.",
          fieldName: "emailLength",
          possibleSuggestions: false,
          required: false,
          type: "textInput",
        },
        {
          id: "tone",
          title: "Tone",
          prompt: "Tone of the email.",
          fieldName: "tone",
          possibleSuggestions: true,
          required: false,
          type: "textInput",
        },
      ],
    },
  ],
};
