import { BoltIcon, PencilIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import AutosizeInput from "react-input-autosize";
import TextareaAutosize from "react-textarea-autosize";
import { Popover } from "@headlessui/react";

export default function InlineTextEditorWithSuggestions({
  defaultText,
  onEnd,
  target,
  parent,
  classes,
  placeholder,
  hideIcons,
  name,
  options,
  disable
}) {
  const [editingValue, setEditingValue] = useState(defaultText); // The value of the input field during edit
  const [value, setValue] = useState(defaultText); // The value of the input field when not being edited
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  // Change the editing value on input change
  const onChange = (event) => setEditingValue(event.target.value);

  // If the user presses enter or escape, end the edit
  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.target.blur();
    }
  };

  // on blur, set the value to the editing value
  const onBlur = (event) => {
    setIsEditing(false);
    if (event.target.value.trim() === "") {
      setEditingValue(defaultText);
      setValue(defaultText);
    } else if (event.target.value !== value) {
      setEditingValue(event.target.value);
      setValue(event.target.value);
      onEnd(target, event.target.value, parent);
    }
  };

  // Select the text when the input is focused
  const onFocus = (event) => {
    setIsEditing(true);
    event.target.select();
  };

  // We run this function when the default text changes to accomodate for deleting of items in the list
  useEffect(() => {
    // If the default text changes, set the value to the new default text
    setEditingValue(defaultText);
    setValue(defaultText);
  }, [defaultText]);

  return (
    <div className="flex relative ">

{!disable && options && Array.isArray(options) && options.length > 1 && (
        <>
          <Popover>
            <Popover.Button>
              <BoltIcon className="valueprop-options-playbook w-8 h-8 p-2 m-1 text-pink-500 shrink-0 rounded-md bg-gray-100 shadow-md hover:bg-pink-500 hover:text-gray-100 transition-all duration-200 " />
            </Popover.Button>

            <Popover.Panel >
              
              <div className="absolute z-50 divide-y divide-gray-200 w-full top-0 max-h-64 overflow-scroll left-0 z-10 bg-white border border-gray-200 rounded-md shadow-md">

              {options.map((option, index) => (
                <Popover.Button
                  className="p-2 cursor-pointer text-left flex w-full gap-3 hover:bg-gray-100"
                  key={index}
                  onClick={() => {
                    setEditingValue(option);
                    setValue(option);
                    onEnd(target, option, parent);
                  }}
                >
                  <BoltIcon className="w-5 h-5 shrink-0 text-pink-500" />
                  <p className="pl-1">{option}</p>
                </Popover.Button>
              ))}

              </div>
              <Popover.Button>
                <div className="fixed left-0 right-0 top-0 bottom-0 bg-black opacity-25 z-40"></div>
              </Popover.Button>
            </Popover.Panel>
          </Popover>
        </>
      )}

      <TextareaAutosize
        className={`flex-1 m-1 px-0 py-0 resize-none bg-transparent border-opacity-0 border-b-2 border-b-white border-t-0 border-x-0 ${disable ? "" : "hover:bg-blue-50  hover:border-gray-100 focus:border-b-blue-500 focus:border-b-2 focus:border-t-0 focus:border-x-0 focus:ring-0 focus:outline-none cursor-pointer"} outline-none ${classes} `}
        type="text"
        aria-label="Field name"
        value={editingValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        ref={inputRef}
        name={name}
        disabled={disable}
      />

  

      {/* {!isEditing && <PencilIcon onClick={() => {inputRef.current.focus()}} className={`group-hover/module:opacity-100 ${hideIcons && "opacity-0"} transition-all duration-300 cursor-pointer w-3 ml-1 text-gray-600`} />} */}

      {/* <ReactTextareaAutosize
        className={`flex-1 resize-none px-0 py-0 bg-transparent border-opacity-0 border-b-2 border-b-white border-t-0 border-x-0 hover:bg-blue-50  hover:border-gray-100 focus:border-b-blue-500 focus:border-b-2 focus:border-t-0 focus:border-x-0 outline-none focus:ring-0 focus:outline-none cursor-pointer ${classes} `}
        type="text"
        aria-label="Field name"
        value={editingValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        rows={1}
      /> */}
    </div>
  );
}
