import React, { useEffect } from "react";
import FormTextField from "../../../ux/FormTextField";
import AssetWizardSizeMultiSelect from "../../../ux/AssetWizardSizeMultiSelect";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import InlineTextEditorWithSuggestions from "../../../ux/InlineTextEditorWithSuggestions";
import AssetFormTextFieldWithSuggestions from "../../../ux/AssetFormTextFieldWithSuggestions";
import AssetWizardSingleSelect from "../../../ux/AssetWizardSingleSelect";
import AssetWizardMultiSelect from "../../../ux/AssetWizardMultiSelect";

export default function AssetWizardFormFieldSet(props) {
  const {
    fieldSet,
    register,
    errors,
    control,
    watch,
    reset,
    setValue,
    options,
    accordionPanel,
    setAccordionPanel,
    disabled,
  } = props;
  // const [open, setOpen] = React.useState(false);

  // Is this accordion panel open?
  const open = accordionPanel === fieldSet.id;

  // Everytime errors changes, check if this fieldset contains any errors
  useEffect(() => {
    if (fieldSet.fields.some((field) => errors[field.fieldName])) {
      setAccordionPanel(fieldSet.id);
    }
  }, [errors]);

  return (
    fieldSet.fields && (
      <div className={`${disabled ? "opacity-30" : "opacity-100"}`}>
        <div
          className={`p-3 flex justify-between transition-all duration-300 ${
            fieldSet.fields.some((field) => errors[field.fieldName])
              ? "bg-red-100 hover:bg-red-200 text-red-500"
              : "bg-gray-100 hover:bg-gray-200 text-gray-900"
          }
            ${disabled ? "cursor-not-allowed" : "cursor-pointer"}
          `}
          onClick={() =>
            accordionPanel === fieldSet.id
              ? !disabled && setAccordionPanel(null)
              : !disabled && setAccordionPanel(fieldSet.id)
          }
        >
          <h2 className="text-md truncate font-semibold flex items-center h-full align-middle">
            {fieldSet.title}
          </h2>
          <button>
            {fieldSet.fields.some((field) => errors[field.fieldName]) ? (
              <ExclamationTriangleIcon className="w-6 ml-2 text-red-500" />
            ) : open ? (
              <ChevronUpIcon className="w-5" />
            ) : (
              <ChevronDownIcon className="w-5" />
            )}
          </button>
        </div>

        <div
          className={`px-3 flex flex-col bg-white gap-1 overflow-hidden transition-all duration-500 ${
            open ? "max-h-[3000px]" : "max-h-0"
          }`}
        >
          {fieldSet.fields.map((field, index) => {
            return (
              <div key={index} className="py-1">
                {field.type === "textInput" && (
                  <>
                    <AssetFormTextFieldWithSuggestions
                      register={register}
                      errors={errors}
                      fieldName={field.fieldName}
                      placeholder={field.prompt}
                      label={field.title}
                      required={field.required}
                      setValue={setValue}
                      options={options[field.fieldName]}
                      tooltipContent={field.tooltip}
                      maxLength={field.maxLength}
                      type="text"
                    />
                  </>
                )}
                {field.type === "multiSelect" && (
                    <AssetWizardMultiSelect
                      register={register}
                      errors={errors}
                      fieldName={field.fieldName}
                      required={field.required}
                      label={field.title}
                      choices={
                        options[field.fieldName]
                          ? options[field.fieldName].options
                          : []
                      }
                      // choices={options[field.fieldName]}
                      tooltipContent={field.tooltip}
                      defaultValue={[]}
                      control={control}
                      watch={watch}
                    />
                )}

                {field.type === "sizeMultiSelect" && (
                  <AssetWizardSizeMultiSelect
                    register={register}
                    errors={errors}
                    fieldName="size"
                    required={field.required}
                    label={field.title}
                    choices={field.choices}
                    options={options[field.fieldName]}
                    tooltipContent={field.tooltip}
                    defaultValue="all"
                    control={control}
                    watch={watch}
                  />
                )}
                {field.type === "singleSelect" && (
                  <AssetWizardSingleSelect
                    register={register}
                    errors={errors}
                    fieldName={field.fieldName}
                    label={field.title}
                    required={field.required}
                    options={options}
                    choices={field.choices}
                    control={control}
                    watch={watch}
                  />
                )}
              </div>
            );
          })}

          {/* Spacer */}
          <div className="h-10"></div>
        </div>
      </div>
    )
  );
}
