exports.pageViewStyle = `

          html {
            background: #eceef4;
            min-height: 100%;
            padding: 0.5rem;
          }

          body {
            background-color: #fff;
            box-shadow: 0 0 4px rgba(0, 0, 0, .15);
            box-sizing: border-box;
            margin: 1rem auto 0;
            max-width: 8.5in;
            min-width: 8.5in;
            max-height: 11in;
            min-height: 11in;
            padding: 3rem 3rem 3rem 3rem;
            font-size: 0.8rem;
          }

          h1 {
            font-size: 2.5rem;
            font-weight: 700;
            line-height: 1.2;
            margin: 0 0 1rem;
          }
        
          ul {
            list-style: disc;
            margin: 0 0 1rem 1rem;
            padding: 0;
          }

          li {
            margin: 0 0 0.5rem;
            padding: 0;
          }

      `