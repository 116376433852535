import { Menu, Transition } from "@headlessui/react";
import {
  ArchiveBoxIcon,
  ArrowUturnLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentArrowDownIcon,
  EllipsisVerticalIcon,
  FolderArrowDownIcon,
  PaperAirplaneIcon,
  PencilIcon,
  TrashIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { get } from "lodash";

import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ShareDialog from "../../../organization/organizationActions/ShareDialog";
import { saveAsset } from "../../../../utils/redux/actions/dataActions/assetActions";
import InlineToolbarEditor from "../../../ux/InlineToolbarEditor";
import { assetTypes } from "../types/assetTypes";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AssetEditorToolbar(props) {
  const { asset } = props;
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const [saving, setSaving] = React.useState(false);

  const handleSave = async () => {
    setSaving(true);
    await dispatch(saveAsset(asset));
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        title: "Asset Saved",
        message: "Your asset was successfuly saved.",
        type: "static",
        meta: {},
      },
    });
    setSaving(false);
  };

  const assetTypeDetails = assetTypes.find(
    (assetType) => assetType.type === asset.type
  );

  const handleUpdateTitle = (target, event, parent) => {
    dispatch({
      type: "UPDATE_ASSET",
      payload: {
        assetId: asset.id,
        asset: {
          ...asset,
          name: event.target.value,
        },
      },
    });
  };

  const handleDelete = () => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        type: "deleteAsset",
        meta: { assetId: asset.id, assetName: asset.name },
      },
    });
  };

  return (
    <>
      <div
        className={`border-b h-16 border-gray-200 bg-white  fixed left-0 top-16 ${
          ui.shrinkSidebar ? "lg:left-16" : "lg:left-64"
        } right-0 z-10`}
      >
        {/* Toolbar*/}
        <div className="flex flex-col justify-center">
          <div className="px-4 sm:px-6 lg:px-4">
            <div className="flex justify-between py-3 gap-6">
              {/* Left buttons */}
              <div className="flex space-x-3 grow ">
                <div
                  className={`flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-${assetTypeDetails.color} p-2 text-white`}
                >
                  <assetTypeDetails.icon />
                </div>
                <InlineToolbarEditor
                  defaultText={asset.name}
                  placeholder={"Enter a name for this asset..."}
                  target="name"
                  onEnd={handleUpdateTitle}
                />
              </div>

              {/* RIGHT BUTTONS */}
              <span className="space-x-3 flex">
                <button
                  onClick={handleDelete}
                  type="button"
                  className="relative -ml-px items-center cursor-pointer rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-blue-50 focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 inline-flex"
                >
                  <TrashIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>

                <ShareDialog />
                <button
                  onClick={handleSave}
                  type="button"
                  className="relative -ml-px items-center cursor-pointer rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-blue-50 focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 inline-flex"
                >
                  <DocumentArrowDownIcon
                    className="mr-2.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>{saving ? "Saving..." : "Save"}</span>
                </button>
                {/* <button
                  type="button"
                  onClick={() => dispatch(publishPlaybook(playbook))}
                  className="relative -ml-px items-center rounded-md border border-gray-100 bg-blue-500 px-4 py-2 text-sm font-medium text-gray-100 hover:bg-blue-800 focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 inline-flex"
                >
                  <PaperAirplaneIcon
                    className="mr-2.5 h-5 w-5 text-gray-100"
                    aria-hidden="true"
                  />
                  <span>Publish</span>
                </button> */}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
