import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { get } from "lodash";
import { inviteCollaborator } from "../../../utils/redux/actions/dataActions";
import FormTextField from "../../ux/FormTextField";

export default function InviteUser() {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  let params = useParams();

  //Submission Logic
  const onSubmit = async (data) => {
    setStep(2)
    const createInvite = await dispatch(
      inviteCollaborator({ organizationId: params.organizationId, ...data })
    );
    if (createInvite.success) {
      reset()
      setStep(1)
    } else {
      setStep(1)
      console.log(createInvite.message);
      setError("email", {
        type: "custom",
        message: `${createInvite.message}`,
      });
    }
  };

  return (
    <>
      <form
        noValidate
        id="new-product-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset disabled={step === 2} className="mt-2 sm:flex sm:items-center">
        <div className="w-full sm:max-w-xs">
          <FormTextField
            register={register}
            errors={errors}
            fieldName="email"
            placeholder="crew@getflight.ai"
            label="Email Address"
            isRequired={true}
            maxLength={40}
            minLength={5}
            type="email"
            autoComplete="email"
            noLabel
          />
        </div>
        <button

          type="submit"
          className={`mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent ${step === 1 ? "bg-blue-600" : "bg-gray-600"} px-4 py-2 font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
        >
          Invite
        </button>
        </fieldset>
      </form>
    </>
  );
}
