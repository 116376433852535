import React from "react";

export default function BasicPageTemplate(props) {
  let { preTitle, title, subTitle, children } = props;

  return (
    <div className="min-h-full">
      <div className="bg-blue-600 sm:px-6 md:px-8 pt-12 pb-6 mb-6">
        <div className="sm:flex sm:space-x-5">
          <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
            <p className="text-sm font-medium text-gray-200">{preTitle}</p>
            <p className="text-xl font-bold text-gray-100 sm:text-2xl">
              {title}
            </p>
            <p className="text-sm font-medium text-gray-300">{subTitle}</p>
          </div>
        </div>
      </div>

      <main className="pb-8">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <section>{children}</section>
        </div>
      </main>
    </div>
  );
}
