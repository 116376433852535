import { Transition } from "@headlessui/react";
import React from "react";
import { WizardStepContainer } from "./templates/TailwindComponents";
import WizardConfirmationTemplate from "./templates/WizardConfirmationTemplate";
import WizardConfirmVariablesTemplate from "./templates/WizardConfirmVariablesTemplate";
import WizardFormTemplate from "./templates/WizardFormTemplate";
import WizardMultiSelectTemplate from "./templates/WizardMultiSelectTemplate";
import WizardTitleTemplate from "./templates/WizardTitleTemplate";
import WizardMultiSelectWithMetaTemplate from "./templates/WizardMultiSelectWithMetaTemplate";

export default function WizardStep(props) {
  const { fieldSet, form, handleComplete, active, stepper, step, index } = props;

  const templateSwitch = (template) => {
    switch (template) {
      case "title":
        return ( <WizardTitleTemplate fieldSet={fieldSet} active={active} stepper={stepper} /> );
      case "form":
        return <WizardFormTemplate fieldSet={fieldSet} active={active} />;
      case "multiSelect":
        return ( <WizardMultiSelectTemplate fieldSet={fieldSet} form={form} active={active} stepper={stepper} /> );
        case "multiSelectMeta":
          return ( <WizardMultiSelectWithMetaTemplate fieldSet={fieldSet} form={form} active={active} stepper={stepper} /> );
      case "confirmVariables":
        return ( <WizardConfirmVariablesTemplate fieldSet={fieldSet} form={form} active={active} stepper={stepper} /> );
      case "confirmation":
        return ( <WizardConfirmationTemplate fieldSet={fieldSet} form={form} handleComplete={handleComplete} active={active} /> );
      default:
        return "Couldn't find template";
    }
  };

  return (
    <WizardStepContainer
      className={`
      ${step < index && "translate-x-full"}
      ${step === index && "translate-x-0"}
      ${step > index && "-translate-x-full"}
      `}
    >
      {templateSwitch(fieldSet.template)}
    </WizardStepContainer>
  );
}
