import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

import { get } from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";


import { useDispatch } from "react-redux";
import { classicStyle } from "../product/assets/textEditor/classicStyle";
import { pageViewStyle } from "../product/assets/textEditor/pageViewStyle";

export default function UniversalTinyMCE(props) {
  const { document, handleBlur } = props;

  // Document Expectations
  // document = {
  //     id: "123",
  //     content: "<p>Some content</p>",
  //     editorView: "classicStyle",
  //     title: "Some Title",
  //     type: "text",
  //     }

  // Blur Action
  // action( content )

  const dispatch = useDispatch();

  const editorRef = useRef(null);
  const isSmallScreen = window.matchMedia("(max-width: 1023.5px)").matches;

  // Determine Page View Content Style
  const contentStyle = (editorView) => {
    if (editorView === "classicStyle") {
      return classicStyle;
    } else if (editorView === "pageViewStyle") {
      return pageViewStyle;
    } else {
      return classicStyle;
    }
  };

  // Blank document placeholder

  return (
    <>
      <Editor
        apiKey="yo1kjdgnv2opj8sf3u9s7f8hhgquli8sfljw7xjlmvkxdnj7"
        onInit={(evt, editor) => (editorRef.current = editor)}
        onBlur={(e, editor) => {
          if (editorRef.current) {
            let content = editorRef.current.getContent();
            handleBlur(content);
          }
        }}
        initialValue={document.content}
        init={{
          selector: "textarea",
          plugins:
            "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
          editimage_cors_hosts: ["picsum.photos"],
          menubar: false,
          toolbar:
            "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | table code | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
          toolbar_sticky: true,
          resize: false,
          toolbar_sticky_offset: isSmallScreen ? 102 : 108,
          autosave_ask_before_unload: true,
          autosave_interval: "30s",
          autosave_prefix: "{path}{query}-{id}-",
          autosave_restore_when_empty: false,
          autosave_retention: "2m",
          image_advtab: true,
          link_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_class_list: [
            { title: "None", value: "" },
            { title: "Some class", value: "class-name" },
          ],
          importcss_append: true,
          file_picker_callback: (callback, value, meta) => {
            /* Provide file and text for the link dialog */
            if (meta.filetype === "file") {
              callback("https://www.google.com/logos/google.jpg", {
                text: "My text",
              });
            }

            /* Provide image and alt text for the image dialog */
            if (meta.filetype === "image") {
              callback("https://www.google.com/logos/google.jpg", {
                alt: "My alt text",
              });
            }

            /* Provide alternative source and posted for the media dialog */
            if (meta.filetype === "media") {
              callback("movie.mp4", {
                source2: "alt.ogg",
                poster: "https://www.google.com/logos/google.jpg",
              });
            }
          },
          templates: [
            {
              title: "New Table",
              description: "creates a new table",
              content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
            },
            {
              title: "Starting my story",
              description: "A cure for writers block",
              content: "Once upon a time...",
            },
            {
              title: "New list with dates",
              description: "New List with dates",
              content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
            },
          ],
          template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
          template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
          height: "100%",
          branding: false,
          image_caption: true,
          quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
          noneditable_class: "mceNonEditable",
          toolbar_mode: "sliding",
          contextmenu: "link image table",
          skin: "tinymce-5",
          content_css: "default",
          content_style: contentStyle(document.editorView),
        }}
      />
    </>
  );
}
