import React, { Fragment } from "react";
import { Controller, useController } from "react-hook-form";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

export default function WizardInlineMultiSelect(props) {
  const {
    fieldName,
    register,
    errors,
    placeholder,
    isRequired,
    maxLength,
    minLength,
    type,
    autoComplete,
    options,
    columns,
    watch,
    defaultValue,
    control,
  } = props;

  const handleChange = (onChange, e, value) => {
    let selectedOptions = value;

    if (selectedOptions.includes(e)) {
      selectedOptions = selectedOptions.filter((item) => item !== e);
    } else {
      selectedOptions.push(e);
    }

    // If "all" is selected, remove all other options
    if (e === defaultValue) {
      selectedOptions = [defaultValue];
    }

    // If an option is selected that is not "all", remove "all"
    if (e !== defaultValue && selectedOptions.includes(defaultValue)) {
      selectedOptions = selectedOptions.filter((item) => item !== defaultValue);
    }

    // If no options are selected, default to "all"
    if (selectedOptions.length === 0) {
      selectedOptions = [defaultValue];
    }

    // If all options are selected, default to "all"
    if (selectedOptions.length === options.length - 1) {
      selectedOptions = [defaultValue];
    }

    onChange(selectedOptions);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const Option = ({ active, onClick, label }) => (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        active
          ? "bg-blue-500 border-transparent text-white hover:bg-blue-800 hover:text-white"
          : "bg-white text-gray-600 hover:bg-blue-50",
        "cursor-pointer w-full py-2"
      )}
    >
      {label}
    </button>
  );

  return (
    <>
    <div className="float-left">
      <Popover as="div" className="relative">
        <Popover.Button className="flex border-b-2 mx-1 border-blue-500 max-w-xs items-center bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-100 focus:ring-offset-2 space-x-1">
          {watch(fieldName) &&
            watch(fieldName).map((item, index) => {
              return (
                <span
                  key={index}
                  className="inline-flex mb-1 items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                >
                  {item}
                </span>
              );
            })}
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel className="absolute left-0 top-4 z-10 mt-2 p-2 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Controller
              defaultValue={[defaultValue]}
              control={control}
              name={fieldName}
              rules={{ required: { value: isRequired, message: "Required" } }}
              render={({ field: { value, onChange } }) =>
                options.map((option, index) => (
                  <Option
                    key={index}
                    label={option.label}
                    active={value.includes(option.value)}
                    onClick={() => handleChange(onChange, option.value, value)}
                  />
                ))
              }
            />
          </Popover.Panel>
        </Transition>
      </Popover>

      {errors[fieldName] && (
        <p className="mt-2 text-sm text-red-600">{errors[fieldName].message}</p>
      )}
    </div>
    </>
  );
}
