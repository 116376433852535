import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { get } from "lodash";
import useNavBarTitle from "../../../../utils/hooks/useNavBarTitle";
import { useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../../../utils/redux/actions/uiActions";
import InlineFullLoader from "../../../../components/ux/inlineFullLoader";
import MainTextEditor from "../../../../components/product/assets/textEditor/MainTextEditor";
import AssetWizardSwitcher from "../../../../components/product/assets/wizards/AssetWizardSwitcher";
import AssetEditorToolbar from "../../../../components/product/assets/textEditor/AssetEditorToolbar";

export default function AssetEditor() {
  let params = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.v2Data);
  const user = useSelector((state) => state.user);
  const ui = useSelector((state) => state.ui);
  let asset = get( data, `assets.${params.assetId}`);


  // Shrink Sidebar
  useEffect(() => {
    dispatch(toggleSidebar(true))

    // TODO: Implement a proper page title mechanism at a higher level
    dispatch({
      type: "SET_NAV_BAR",
      payload: {
        title: "Asset Editor",
        sticky: true,
        stickyOffset: -1,
      },
    })
  }, [])

  return asset ? (
    <>
      <h1 id="primary-heading" className="sr-only"> {" "} Asset Editor{" "} </h1>
      <AssetEditorToolbar asset={asset} />
      
      <div className="order-first block flex-col flex-shrink-0 top-32 bottom-0 pb-2 fixed">
        <AssetWizardSwitcher type={asset.type} />
      </div>


      <main className={`fixed transition-all duration-700 bottom-0 top-32 z-10 right-0 ${ui.shrinkSidebar ? "lg:left-[448px] left-[385px]" : "lg:left-[640px] left-[385px]"}`}>
        <MainTextEditor />
      </main>
    </>
  ) : <InlineFullLoader />;
}
