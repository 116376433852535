import React from "react";

export default function WizardCardTitle(props) {
    const { title, subtitle, error} = props;
  return (
    <div className="border-b border-gray-200 pb-5">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {title}
      </h3>
      <p className="mt-2 max-w-4xl text-sm text-gray-500">
        {subtitle}
      </p>
      {error && (
        <p className="text-red-500 text-sm mt-2 font-medium p-2 bg-red-50 rounded-md border-red-300 border">
          {error}
        </p>
      )}
    </div>
  );
}
