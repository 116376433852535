const initialState = {
  loading: false,
  fullScreenLoading: true,
  permissionImpactedLoading: false,
  shrinkSidebar: false,
  granularLoading: [],
  errors: {
    error: false,
    message: null,
    action: null,
  },
  navBar: {
    preTitle: "",
    title: "",
    subtitle: "",
    sticky: true,
    stickyOffset: 64,
  },
  notification: {
    show: false,
    type: "",
    title: "",
    message: "",
    meta: {},
  },
  modal: {
    show: false,
    type: "",
    meta: {},
  },
  slideOver: {
    show: false,
    type: "",
    meta: {},
  },
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FULL_SCREEN_LOADING":
      return {
        ...state,
        fullScreenLoading: true,
      };

    case "ADD_GRANULAR_LOADING":
      return {
        ...state,
        granularLoading: [...state.granularLoading, ...action.payload],
      };
    case "REMOVE_GRANULAR_LOADING":
      return {
        ...state,
        granularLoading: state.granularLoading.filter(
          (item) => !action.payload.includes(item)
        ),
      };
    case "SHOW_NOTIFICATION":
      return {
        ...state,
        notification: {
          show: true,
          type: action.payload.type,
          title: action.payload.title,
          message: action.payload.message,
          meta: { ...action.payload.meta },
        },
      };

    case "CLEAR_NOTIFICATION":
      return {
        ...state,
        notification: {
          show: false,
          title: "",
          message: "",
        },
      };

    case "SHOW_MODAL":
      return {
        ...state,
        modal: {
          show: true,
          type: action.payload.type,
          meta: { ...action.payload.meta },
        },
      };

    case "CLEAR_MODAL":
      return {
        ...state,
        modal: {
          show: false,
          title: "",
          message: "",
        },
      };

    case "SHOW_SLIDEOVER":
      return {
        ...state,
        slideOver: {
          show: true,
          type: action.payload.type,
          meta: { ...action.payload.meta },
        },
      };

    case "HIDE_SLIDEOVER":
      return {
        ...state,
        slideOver: {
          show: false,
          title: "",
          message: "",
        },
      };

    case "SET_PERMISSION_IMPACTED_LOADING":
      return {
        ...state,
        permissionImpactedLoading: true,
      };

    case "CLEAR_PERMISSION_IMPACTED_LOADING":
      return {
        ...state,
        permissionImpactedLoading: false,
      };

    case "TOGGLE_SIDEBAR":
      return {
        ...state,
        shrinkSidebar: action.payload,
      };

    case "STOP_FULL_SCREEN_LOADING":
      return {
        ...state,
        fullScreenLoading: false,
      };

    case "SET_NAV_BAR":
      return {
        ...state,
        navBar: {
          preTitle: action.payload.preTitle,
          title: action.payload.title,
          subTitle: action.payload.subTitle,
          sticky: action.payload.sticky,
          stickyOffset: action.payload.stickyOffset,
        },
      };
    case "SET_UNAUTHENTICATED":
      return initialState;
    default:
      return state;
  }
};

export default uiReducer;
