import React from "react";

export default function FormTextArea(props) {
  const {
    fieldName,
    register,
    defaultValue,
    errors,
    label,
    placeholder,
    isRequired,
    maxLength,
    minLength,
    type,
    rows,
    columns,
    autoComplete,
    showCount,
    noLabel,
  } = props;

  // Consistent Patterning
  const enforcePattern = (type) => {
    let output = {}

    // Email Pattern
    if (type === 'email') {
      output = {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email address"
      }
    }
    
    return output
  }

  return (
    <div>
      {!noLabel && (
      <label
        htmlFor="password"
        className="block text-sm font-medium text-gray-700"
      >
        {label}  {showCount && <span className="text-gray-400">&#40;{showCount} / {maxLength} characters&#41;</span>}
      </label>
      )}
      <div className="mt-1">
        <textarea
          id={fieldName}
          rows={rows}
          columns={columns}
          placeholder={placeholder}
          name={fieldName}
          type={type}
          autoComplete={autoComplete}
          required={isRequired}
          {...register(fieldName, {
            required: {
              value: isRequired,
              message: "Required",
            },
            maxLength: {
              value: maxLength,
              message: `Must be less than ${maxLength} characters`,
            },
            minLength: {
              value: minLength,
              message: `Must be more than ${minLength} characters`,
            },
            pattern: {
              ...enforcePattern(type)
            }
          })}
          className={errors[fieldName] ? "formTextField-error" : "formTextField-input"}
        ></textarea>
      </div>
      {errors[fieldName] && (
        <p className="mt-2 text-sm text-red-600">{errors[fieldName].message}</p>
      )}
    </div>
  );
}
