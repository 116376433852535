import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";

export default function Tooltip(props) {

  const { content } = props;

  return content && (
    <div className="float-left">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
            //   className={`
            //     ${open ? "" : "text-opacity-90"}
            //     group p-2 items-centerrounded-full text-base bg-blue-200 font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <InformationCircleIcon className="w-5 h-5 text-gray-400 transition-all duration-300 hover:text-blue-400" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-50 max-w-lg w-64 -translate-x-full transform px-2 sm:px-0 ">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="bg-blue-200 text-blue-800 p-4 text-sm">{content}</div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

