import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { get } from "lodash";

export default function AssetWizardPlaybookDropdown(props) {
  const { getSuggestions } = props;

  const [selected, setSelected] = useState({ name: "Select a Playbook" });
  const params = useParams();

  // Get Product Information From Store
  const data = useSelector((state) => state.v2Data);
  const playbooks = get(data, `playbooks`, {});
  let productPlaybooksObject = get(
    data,
    `products.${params.productId}.playbooks`,
    {}
  );

  // filter playbooks for this product
  let filteredPlaybooks = Object.keys(playbooks)
    .filter((key) => productPlaybooksObject.includes(key))
    .reduce((obj, key) => {
      obj[key] = playbooks[key];
      return obj;
    }, {});

  //  Create a new object from playbooks object and add a 'none' option
  const playbooksList = {
    none: { name: "No Playbook (use core product information)", id: "none" },
    ...filteredPlaybooks,
  };

  // Handle Change
  const handleSelect = (e) => {
    setSelected(e);
    getSuggestions(e.id);
  };

  



  return (
    <div className="">
      <Listbox value={selected} onChange={handleSelect}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-300 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block ">{selected.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute cursor-pointer mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-30">
              {Object.keys(playbooksList).map((playbook, playbookIdx) => (
                <Listbox.Option
                  key={playbookIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 group border-b pr-4 ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }
                  value={playbooksList[playbook]}
                  //   value={playbook}
                >
                  {({ selected }) => (
                    <>
                      <div>
                        <span
                          className={`block cursor-pointer  ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {playbooksList[playbook].name}
                        </span>

                        {playbooksList[playbook].usecases && (
                          <div className="w-full max-h-0 overflow-hidden transition-all duration-300 group-hover:max-h-20">
                            <span className="bg-white mr-2 text-gray-800 capitalize mt-1 inline-flex items-center rounded-full px-3 py-0.5 text-xs">
                              {playbooksList[playbook].usecases}
                            </span>

                            <span className="bg-white mr-2 text-gray-800 capitalize mt-1 inline-flex items-center rounded-full px-3 py-0.5 text-xs ">
                              {playbooksList[playbook].industries}
                            </span>

                            {playbooksList[playbook].size &&
                              playbooksList[playbook].size.map(
                                (size, index) => (
                                  <span
                                    key={index}
                                    className="bg-white mr-2 text-gray-800 capitalize mt-1 inline-flex items-center rounded-full px-3 py-0.5 text-xs "
                                  >
                                    {size}
                                  </span>
                                )
                              )}
                          </div>
                        )}

                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
