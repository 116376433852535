import React from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import GlobalLogoV2 from "../../assets/brand/GlobalLogoV2";
import GlobalIcon from "../../assets/brand/GlobaIcon";

export default function SuspenseLoaderLinear() {


  return (
    
      <div className="loading-bar fixed h-2 z-50 left-0 top-0 right-0">
      </div>
  );
}
