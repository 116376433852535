import React from "react";
import TextLoop from "react-text-loop";
import { TypeAnimation } from "react-type-animation";
import tw from "tailwind-styled-components";
import InlineLoader from "../../../../../ux/InlineLoader";

const AnimatedSpan = tw.span`
text-md
text-blue-600
font-medium
`;

const AnimationContainer = tw.div`
flex
flex-col
h-full
w-full
justify-center
items-center
`;

export default function WizardAnimation(props) {
  const { form, fieldSet, precursorArray } = props;

  let precursorText = precursorArray || ["Generating"];

  let animatedSections = {
    precursorText: precursorText,
    ...form.watch(),
  };

  const interval = 40000;

  let speedMultipliers = [];

  Object.keys(animatedSections).map((key, index) => {
    if (Array.isArray(animatedSections[key])) {
        const length = animatedSections[key].length;
        speedMultipliers.push(length);
        const previous = speedMultipliers[index - 1];
        if (previous) {
          speedMultipliers[index] = speedMultipliers[index] * previous;
        }
    } else {
      speedMultipliers.push(1);
    }
    return true;
  });

  const sum = speedMultipliers.reduce((partialSum, a) => partialSum + a, 0);
  const newArray = Array.from(Array(sum).keys());
  const strings = [];

  newArray.map((item, index) => {
    let lineItem = [];
    let arrayIndex = index;

    Object.keys(animatedSections).map((key, index) => {
      if (Array.isArray(animatedSections[key])) {
        let length = animatedSections[key].length - 1;
        const percentage = arrayIndex / sum;
        lineItem.push(animatedSections[key][Math.round(percentage * length)]);
      } else {
        lineItem.push(animatedSections[key]);
      }
    });

    strings.push(lineItem.join(" "));
    strings.push(100);
    return true;
  });

  return (
    <AnimationContainer>
      <InlineLoader />

      <TypeAnimation
        sequence={strings}
        wrapper="div"
        cursor={true}
        repeat={Infinity}
        speed={50}
        className="text-md text-blue-600 font-medium"
      />

{JSON.stringify(strings)}

      {/* <div className="flex flex-row align-middle items-center gap-1">
        {Object.keys(animatedSections).map((key, index) => {
          return (
            <>
              <TextLoop
                key={index}
                springConfig={{ stiffness: 180, damping: 8 }}
                fade={true}
                interval={interval / speedMultipliers[index]}
              >
                {animatedSections[key].map((item, index) => {
                  return <AnimatedSpan key={index}>{item}</AnimatedSpan>;
                })}
              </TextLoop>
              <AnimatedSpan> </AnimatedSpan>
            </>
          );
        })}
      </div> */}
    </AnimationContainer>
  );
}
