import React from "react";
import {
  WizardStepTitle,
  WizardStepSubtitle,
  WizardStepBody,
  WizardStepIcon,
  WizardActionPanel,
  WizardContentContainer,
  WizardTitleIcon,
  WizardCenteredRow,
  WizardTitleTitle,
  WizardTitleContainer,
  WizardActionButton,
  WizardSecondaryButton
} from "./TailwindComponents";
import AIProcessingAnimation from "../../../../../../ux/AIProcessingAnimation";

export default function WizardTitleTemplate(props) {

  const { fieldSet, active, stepper } = props;
  return (
    <>
      <WizardContentContainer>
        <WizardTitleContainer>
        <WizardCenteredRow>
          <WizardTitleIcon className={fieldSet.color}>
            <fieldSet.icon className="text-white" />
          </WizardTitleIcon>
        </WizardCenteredRow>
        <WizardTitleTitle>
          {fieldSet.title}
        </WizardTitleTitle>
        <WizardStepSubtitle>{fieldSet.subtitle}</WizardStepSubtitle>
        <WizardStepBody>{fieldSet.content}</WizardStepBody>
        </WizardTitleContainer>

      </WizardContentContainer>

      <WizardActionPanel>

{/* TODO: Import from another playbook */}
        {/* <WizardSecondaryButton>
          Import from another playbook
        </WizardSecondaryButton> */}
        <WizardActionButton onClick={() => stepper("next")}>
          Get Started
        </WizardActionButton>

      </WizardActionPanel>
    </>
  );
}
