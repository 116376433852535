import { Bars3Icon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import tw from "tailwind-styled-components";
import InlineCardTitleEditor from "../../../../ux/InlineCardTitleEditor";
import FeatureItem from "./FeatureItem";
import ProofPointsList from "./ProofPointsList";
import EmptyStateButton from "../../../../ux/EmptyStateButton";

const BenefitStyledColumn = tw.div`
    flex
    flex-col
    bg-white
    border-gray-300
    w-full
    border
    rounded-md
    relative
    `;

const FeatureList = tw.div`
    flex
    flex-col
    gap-4
    ${(props) => (props.isDraggingOver ? "bg-gray-200" : "bg-none")}
    transition
    duration-200
    ease-in-out
    h-full
    w-full
    p-3
    rounded-b-md
    `;

const BenefitTitle = tw.div`
    flex  
    justify-between
    `;

const BenefitDescription = tw.div`

    text-gray-700
    cursor-pointer
    font-medium
    `;

const BenefitActions = tw.div`
    flex
    justify-end
  gap-3
  pl-3
  
    `;

const DraggableSectionHandle = tw.div`
    h-5
    w-5
    text-gray-400
`;

const BenefitAction = tw.div`
    h-5
    w-5
    text-gray-400
    cursor-pointer
`;

const BenefitCardTitleBar = tw.div`
    bg-gray-200
    rounded-t-md
    flex
    flex-col
    p-3
    border-b
    w-full
`;

const Title = tw.div`
flex-grow

`;

const ProofPointsSection = tw.div`
    flex
    flex-col
    p-3
    justify-between
    border-b
    text-sm
`;

const ProofPointsTitle = tw.div`
    text-sm
    justify-between
    flex
`;

const FeatureListTitle = tw.div`
    text-sm
    col-span-2
    justify-between
    flex
    items-center
`;

export default function BenefitColumn(props) {
  const {
    benefit,
    features,
    proofpoints,
    index,
    productId,
    editProofPoint,
    editFeature,
    updateBenefitDetails,
    addNewFeature,
    removeFeature,
  } = props;
  return (
    <Draggable draggableId={benefit.id} index={index}>
      {(provided) => (
        <BenefitStyledColumn
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <BenefitCardTitleBar>
            <BenefitTitle {...provided.dragHandleProps}>
              <Title>
                <InlineCardTitleEditor
                  defaultText={benefit.title}
                  parent={benefit.id}
                  onEnd={updateBenefitDetails}
                  target="title"
                  classes={"text-md"}
                />
              </Title>

              <BenefitActions>
                <BenefitAction onClick={() => props.removeBenefit(benefit.id)}>
                  <TrashIcon />
                </BenefitAction>
                <DraggableSectionHandle {...provided.dragHandleProps}>
                  <Bars3Icon />
                </DraggableSectionHandle>
              </BenefitActions>
            </BenefitTitle>

            <BenefitDescription>
              <InlineCardTitleEditor
                defaultText={benefit.description}
                parent={benefit.id}
                onEnd={updateBenefitDetails}
                target="description"
                classes={"text-sm"}
              />
            </BenefitDescription>
          </BenefitCardTitleBar>

          <ProofPointsSection>
            <ProofPointsTitle>Proof Points</ProofPointsTitle>
            <ProofPointsList
              proofpoints={proofpoints}
              benefitId={benefit.id}
              productId={productId}
              editProofPoint={editProofPoint}
            />
          </ProofPointsSection>
          <Droppable droppableId={benefit.id} type="feature">
            {(provided, snapshot) => (
              <FeatureList
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
                {...provided.droppableProps}
              >
                <FeatureListTitle>
                  Features
                  {/* {features.length > 0 && (
                    <button
                      className="flex text-xs items-center rounded-md border border-gray-300 py-1 px-2 gap-2 hover:bg-gray-200 transition-all"
                      onClick={() => addNewFeature(benefit.id)}
                    >
                      <PlusIcon className="h-5 cursor-pointer" />
                      ADD
                    </button>
                  )} */}
                </FeatureListTitle>

                {features.length > 0 &&
                  features.map((feature, index) => (
                    <FeatureItem
                      key={feature.id}
                      benefitId={benefit.id}
                      feature={feature}
                      index={index}
                      removeFeature={removeFeature}
                      onEnd={editFeature}
                    />
                  ))}
                <EmptyStateButton
                  title="Add New Feature"
                  shrink
                  icon={<PlusIcon />}
                  action={() => addNewFeature(benefit.id)}
                />
                {provided.placeholder}
              </FeatureList>
            )}
          </Droppable>
        </BenefitStyledColumn>
      )}
    </Draggable>
  );
}
