const { PencilIcon, PuzzlePieceIcon } = require("@heroicons/react/24/outline");
const { defaultFieldTypes } = require("./defaultFieldTypes");


exports.productOnepagerOverview = {
  overview: {
    id:
      "productOnepagerOverview",
    name:
      "Product One-pager",
    description:
      "Create a product one-pager for a specific target audience..",
    pageTitle:
      "New Product One-Pager",
    pageDescription:
      "To generate your one-pager, complete the information below. Certain fields are optional, but providing more information will result in a more targeted one-pager.",
    icon:
      PuzzlePieceIcon,
    color:
      "bg-blue-500",
    playbookTooltip:
      "Pre-fill this form with your product information?",
  },
  fieldSet: [
    {
      id: "targetAudience",
      title: "Target Audience",
      fields: [
        defaultFieldTypes.industries,
        defaultFieldTypes.usecases,
        defaultFieldTypes.size,
      ],
    },
    {
      id: "onepagerContent",
      title: "One-Pager Content",
      fields: [
        defaultFieldTypes.productName,
        defaultFieldTypes.valueProp,
        defaultFieldTypes.keyDifferentiator,
        defaultFieldTypes.keyBenefitMulti,
        defaultFieldTypes.marketTrend,
        defaultFieldTypes.tone,
      ],
    }
  ],
};
