exports.defaultFieldTypes = {
  industries: {
    id: "industries",
    title: "Industry",
    prompt: "Target industry.",
    fieldName: "industries",
    maxLength: 200,
    type: "textInput",
  },
  usecases: {
    id: "usecases",
    title: "Use Cases",
    prompt: "Target use case.",
    fieldName: "usecases",
    maxLength: 200,
    type: "textInput",
  },
  size: {
    id: "size",
    title: "Organization Size",
    prompt: "Target organization size.",
    fieldName: "size",
    required: true,
    requiredErrorMessage: "You must provide a target organization size.",
    type: "sizeMultiSelect",
    choices: [
      { value: "all", label: "All" },
      { value: "small", label: "Small" },
      { value: "medium", label: "Medium" },
      { value: "large", label: "Large" },
      { value: "enterprise", label: "Enterprise" },
    ],
  },
  jobTitle: {
    id: "jobTitle",
    title: "Job Title",
    prompt: "Enter a job title.",
    fieldName: "jobTitle",
    maxLength: 200,
    type: "textInput",
  },
  productName: {
    id: "productName",
    title: "Product Name",
    prompt: "Enter the product name.",
    fieldName: "productName",
    required: true,
    requiredErrorMessage: "You must provide a product name.",
    maxLength: 200,
    type: "textInput",
  },
  valueProp: {
    id: "valueProp",
    title: "Value Proposition",
    prompt: "Your product value proposition.",
    fieldName: "valueProp",
    required: true,
    requiredErrorMessage: "You must provide a value proposition.",
    maxLength: 2000,
    type: "textInput",
  },
  keyBenefit: {
    id: "keyBenefit",
    title: "Key Benefit Focus",
    prompt: "Select a key benefit",
    fieldName: "keyBenefit",
    required: false,
    maxLength: 2000,
    type: "textInput",
    tooltip:
      "To focus this email on a key benefit, select a benefit. Leave blank if the email should focus on the overall value proposition.",
  },
  keyBenefitMulti: {
    id: "keyBenefitMulti",
    title: "Select Key Benefits to include",
    prompt: "Select a key benefit",
    fieldName: "keyBenefitMulti",
    required: true,
    maxLength: 2000,
    type: "multiSelect",
  },
  keyDifferentiator: {
    id: "keyDifferentiator",
    title: "Key Differentiator",
    prompt: "Your product's key differentiator.",
    fieldName: "keyDifferentiator",
    maxLength: 2000,
    type: "textInput",
  },
  featureNameAndDescription: {
    id: "featureNameAndDescription",
    title: "Feature",
    prompt: "Enter Feature Name and Description.",
    fieldName: "featureNameAndDescription",
    required: true,
    requiredErrorMessage: "You must enter feature details.",
    maxLength: 2000,
    type: "textInput",
    tooltip:
      "Include the name a description of the feature that you want to announce in this email.",
  },
  problem: {
    id: "problem",
    title: "Problem Feature Solves",
    prompt: "Enter the problem this feature solves.",
    fieldName: "problem",
    required: false,
    maxLength: 1000,
    type: "textInput",
  },
  additionalInfo: {
    id: "additionalInfo",
    title: "Additional Context to Include",
    prompt: "Enter any additional information.",
    tooltip: "Enter any additional content that whould be included in the email. For example, the release date or key takeaways.",
    fieldName: "additionalInfo",
    required: false,
    maxLength: 2000,
    type: "textInput",
  },
  marketTrend: {
    id: "marketTrend",
    title: "Market Trend",
    prompt: "Enter a market trend to include.",
    fieldName: "marketTrend",
    required: false,
    maxLength: 400,
    type: "textInput",
  },
  cta: {
    id: "cta",
    title: "Call to Action",
    prompt: "Enter a call to action.",
    fieldName: "cta",
    required: true,
    requiredErrorMessage: "You must select a call to action.",
    type: "textInput",
    maxLength: 400,
    presetOptions: true,
    options: {
      value: "Book a demo",
      editable: true,
      options: [
        { title: "Book a demo", content: "Reply now to book a demo" },
        {
          title: "Invite to an event",
          content: "Please join us at our event [details]",
        },
        { title: "Visit a website", content: "Visit [insert website]" },
        {
          title: "View a case study",
          content: "Read our case study on [topic]",
        },
        { title: "View a blog post", content: "View a blog post" },
        { title: "Download a resource", content: "Download a resource" },
        { title: "Start a trial", content: "Start a free trial" },
        { title: "Enter custom CTA", content: "" },
      ],
    },
  },
  emailLength: {
    id: "emailLength",
    title: "Email Length",
    prompt: "Email body length.",
    fieldName: "emailLength",
    required: true,
    type: "singleSelect",
    default: "100-150 words",

    choices: [
      { value: "Less than 100 words", label: "Less than 100 words" },
      { value: "100-150 words", label: "Between 100-150 words" },
      { value: "150 or more words", label: "150 or more words" },
    ],
  },
  tone: {
    id: "tone",
    title: "Tone",
    prompt: "Tone of the email.",
    fieldName: "tone",
    required: true,
    maxLength: 200,
    requiredErrorMessage: "You must enter a tone.",
    type: "textInput",
  },
};
