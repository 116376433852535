import React, { useEffect, useState } from "react";
import ReactTextareaAutosize from "react-textarea-autosize";

export default function InlineEditorWithPlaceholder({
  defaultText,
  onEnd,
  target,
  parent,
  name
}) {
  const [editing, setEditing] = useState(false); // Is the component currently being edited?
  const [editingValue, setEditingValue] = useState(defaultText); // The value of the input field during edit
  const [value, setValue] = useState(defaultText); // The value of the input field when not being edited

  // Change the editing value on input change
  const onChange = (event) => setEditingValue(event.target.value);

  // If the user presses enter or escape, end the edit
  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.target.blur();
    }
  };

  // on blur, set the value to the editing value
  const onBlur = (event) => {

    if (event.target.value.trim() === "") {
      if (target === "new") {

        // If the value is empty and the target is new, don't trigger the onEnd callback
        setEditingValue(value);
        setValue(value);
        
      } else {

        // If the value is empty, set it to the default value and trigger the onEnd callback. Use the higher level function to decide if you want to delete the item or not
        setEditingValue(value);
        setValue(value);
        onEnd(target, event, parent);
        
      }
    } else if (event.target.value !== value) {
      
      // If the value is not empty and is different, set the non-editing value and trigger the onEnd callback

      if (target === "new") {
        // If the  and the target is new, don't trigger the onEnd callback
        onEnd(target, event, parent);
        setEditingValue(defaultText);
        setValue(defaultText);
      } else {
        // If the value is empty, set it to the default value and trigger the onEnd callback. Use the higher level function to decide if you want to delete the item or not
        setEditingValue(event.target.value);
        setValue(event.target.value);
        onEnd(target, event, parent);
      }
    }
  };

  // Select the text when the input is focused
  const onFocus = (event) => {
    event.target.select();
  };


  // We run this function when the default text changes to accomodate for deleting of items in the list
  useEffect(() => {
    // If the default text changes, set the value to the new default text
    setEditingValue(defaultText);
    setValue(defaultText);
  }, [defaultText]);

  return (
    <>
      <ReactTextareaAutosize
        className="flex-1 resize-none w-full text-sm px-0 py-0 bg-transparent border-opacity-0 border-b-2 border-b-white border-t-0 border-x-0 focus:border-b-blue-500 focus:border-b-2 focus:border-t-0 focus:border-x-0 outline-none focus:ring-0 focus:outline-none cursor-pointer"
        type="text"
        aria-label="Field name"
        value={editingValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={target === "new" ? "Add Proof Point" : "Delete"}
        rows={1}
        name={name}
      />
    </>
  );
}
