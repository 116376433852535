import React from "react";
import { useDispatch } from "react-redux";
import InlineEditorWithPlaceholder from "../../../../ux/InlineEditorWithPlaceholder";
import InlineEditTextField from "../../../../ux/InlineEditTextField";
import tw from "tailwind-styled-components";

const Marker = tw.span`
  px-1
  text-gray-600
  text-sm
  uppercase
  tracking-wide
`;

const ListItem = tw.li`
  flex
  my-1
`;

export default function ProofPointsList(props) {
  const { proofpoints, benefitId, editProofPoint } = props;

  // const updateProofPoints = (target, event) => {

  //     let newProofPointsOrder = Array.from(proofpoints);
  //     let newState

  //     // If new proofpoint is blank
  //     if (event.target.value.trim() === "") {

  //         newProofPointsOrder.splice(newProofPointsOrder.indexOf(target), 1);

  //         newState = {
  //             ...proofpoints,
  //             benefitOrder: newBenefitOrder,
  //             benefits: {
  //               ...initialData.benefits,
  //               [benefitId]: undefined,
  //             },
  //           };

  //     }

  //     console.log(target)
  //     console.log(benefitId)
  //     console.log(event.target.value)

  //     // Remove Benefit

  //  newState = {
  //   ...initialData,
  //   benefitOrder: newBenefitOrder,
  //   benefits: {
  //     ...initialData.benefits,
  //     [benefitId]: undefined,
  //   },
  // };

  // // update state
  // dispatch(
  //   updateBenefitsFeaturesMatrix({
  //     productId: productId,
  //     newState: newState,
  //   })
  // );

  // }

  return (
    <>
      <ul className="w-full">
        {proofpoints.map((proofpoint, index) => (
          <ListItem key={index}>
            <Marker>
              {index + 1}
              {". "}
            </Marker>

            <InlineEditorWithPlaceholder
              defaultText={proofpoint.content}
              parent={benefitId}
              target={proofpoint.id}
              onEnd={editProofPoint}
            />
            
          </ListItem>
        ))}
        <ListItem>
          <Marker>
            {proofpoints.length + 1}
            {". "}
          </Marker>

          <InlineEditorWithPlaceholder
            defaultText=""
            target="new"
            onEnd={editProofPoint}
            parent={benefitId}
          />
        </ListItem>
      </ul>
    </>
  );
}
