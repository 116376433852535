import React from "react";
import { useParams } from "react-router-dom";

import { useDispatch } from "react-redux";

import tw from "tailwind-styled-components";
import Wizard from "../../playbooks/editor/modules/wizards/Wizard";
import AssetWizardForm from "./AssetWizardForm";
import { coldOutreachEmail } from "./templates/coldOutreachEmail";
import { leaveBehind } from "./templates/leaveBehind";
import { featureAnnouncementEmail } from "./templates/featureAnnouncementEmail";
import { productOnepagerOverview } from "./templates/productOnepagerOverview";
// import {productOnepagerOverview } from "./templates/productOnepagerOverview"

const NavigatorSidebar = tw.div`
absolute
w-96
flex
h-96
flex-col
border-r
border-gray-200
h-full
bg-white
`;

const NavigatorMain = tw.div`
    overflow-y-auto
    grow
`;

export default function AssetWizardSwitcher(props) {
  const { type } = props;

  // Display Content Type
  const displayWizard = () => {
    switch (type) {
      case "coldOutreachEmail":
        return coldOutreachEmail;
      case "leaveBehind":
        return leaveBehind;
      case "featureAnnouncementEmail":
        return featureAnnouncementEmail;
      case "productOnepagerOverview":
        return productOnepagerOverview;
      default:
        return "Empty";
    }
  };

  let params = useParams();
  const dispatch = useDispatch();

  return (
    <NavigatorSidebar>
      <AssetWizardForm
        type={type}
        wizardFields={displayWizard(type)}
        assetId={params.assetId}
      />
    </NavigatorSidebar>
  );
}
