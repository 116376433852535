import React, { Fragment, useEffect } from "react";
import { Controller, useController } from "react-hook-form";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import Tooltip from "./Tooltip";

export default function AssetWizardMultiSelect(props) {
  const {
    fieldName,
    register,
    noLabel,
    label,
    errors,
    placeholder,
    options,
    required,
    maxLength,
    minLength,
    type,
    autoComplete,
    choices,
    columns,
    tooltipContent,
    watch,
    defaultValue,
    control,
  } = props;

  // Determine if we should disable editing
  const editable = options ? options.editable : true;

  const handleChange = (onChange, e, value) => {
    if (editable) {
      let selectedOptions = value;

      if (selectedOptions.some(({ title }) => title === e.title)) {
        selectedOptions = selectedOptions.filter(
          (item) => item.title !== e.title
        );
      } else {
        selectedOptions.push(e);
      }

      return onChange(selectedOptions);
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // Filter out objects where the content is ""
  const filteredChoices = choices.filter((choice) => choice.content !== "");

  const primaryChoices = choices.filter(
    (choice) => choice.source === "playbook"
  );

  const secondaryChoices = choices.filter(
    (choice) => choice.source === "product"
  );

  const Option = ({ active, onClick, title }) => (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        active ? "border-blue-500 bg-blue-100" : "hover:border-blue-500",
        editable ? "cursor-pointer" : "cursor-not-allowed",
        "block w-full appearance-none rounded-md border border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm py-2 px-1"
      )}
    >
      {title}
    </button>
  );

  // TODO: Defauly to values if possible. If we have a playbook options, automatically add them to the field value, otherwise add the product options

  return (
    <>
      <div className="m-1">
        <div className="flex flex-row  justify-between">
          {!noLabel && (
            <label className="text-sm font-medium text-gray-700 mb-2">
              {label} {required && <span className="text-red-500">*</span>}{" "}
              {!editable && (
                <span className="ml-1 text-xs text-gray-500">
                  (Locked to selected playbook)
                </span>
              )}
            </label>
          )}
          <div className="ml-1">
            <Tooltip content={tooltipContent} />
          </div>
        </div>

        {primaryChoices.length > 0 && (
          <div className="flex flex-col gap-2 mb-2">
            <span className="text-xs text-gray-500">Adapted key benefits from playbook</span>
            <Controller
              defaultValue={defaultValue}
              control={control}
              name={fieldName}
              rules={{ required: { value: required, message: "Required" } }}
              render={({ field: { value, onChange } }) =>
                primaryChoices.map((option, index) => (
                  <Option
                    key={index}
                    title={option.title}
                    active={value.some(({ title }) => title === option.title)}
                    onClick={() => handleChange(onChange, option, value)}
                  />
                ))
              }
            />
          </div>
        )}

        {secondaryChoices.length > 0 && (
          <div className="flex flex-col gap-2">
            <span className="text-xs text-gray-500">
              Key benefits from core product positioning
            </span>

            <Controller
              defaultValue={defaultValue}
              control={control}
              name={fieldName}
              rules={{ required: { value: required, message: "Required" } }}
              render={({ field: { value, onChange } }) =>
                secondaryChoices.map((option, index) => (
                  <Option
                    key={index}
                    title={option.title}
                    active={value.some(({ title }) => title === option.title)}
                    onClick={() => handleChange(onChange, option, value)}
                  />
                ))
              }
            />
          </div>
        )}

        {errors[fieldName] && (
          <p className="mt-2 text-sm text-red-600">
            {errors[fieldName].message}
          </p>
        )}
      </div>
    </>
  );
}
