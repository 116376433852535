import tw from "tailwind-styled-components";

const Card = tw.div`
bg-white
shadow
rounded-lg
`;

const CardTitle = tw.h3`
text-lg
font-medium
leading-6
text-gray-900
`;

const CardContent = tw.div`
px-4
py-5
sm:p-6
`;




export { Card, CardContent, tw };
