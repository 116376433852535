import { RadioGroup } from "@headlessui/react";
import React from "react";
import { Controller, useController } from "react-hook-form";

export default function FormMultiSelect(props) {
  const {
    fieldName,
    register,
    errors,
    label,
    placeholder,
    isRequired,
    maxLength,
    minLength,
    type,
    autoComplete,
    noLabel,
    options,
    columns,
    watch,
    defaultValue,
    control,
  } = props;


  const handleChange = (onChange, e, value) => {
    let toneArray = value
    if (toneArray.includes(e)) {
      toneArray = toneArray.filter((item) => item !== e)
    } else {
      toneArray.push(e)
    }
    onChange(toneArray)
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const Option = ({ active, onClick, label }) => (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        active
          ? "bg-blue-500 border-transparent text-white hover:bg-blue-800 hover:text-white"
          : "bg-white border-gray-300 text-gray-600 hover:bg-blue-50",
        "cursor-pointer focus:outline-none border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium sm:flex-1"
      )}
    >
      {label}
    </button>
  );
  

  return (
    <div>
      {!noLabel && (
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="mt-1">
        <div className={`mt-2 grid grid-cols-${columns} gap-3`}>
          <Controller
            defaultValue={[]}
            control={control}
            name={fieldName}
            rules={{ required: {value: isRequired, message: `Please select at least one ${label} option`} }}
            render={({ field: { value, onChange } }) =>
              options.map((option, index) => (
                
                <Option
                  key={index}
                  label={option.label}
                  active={value.includes(option.value)}
                  onClick={() => handleChange(onChange, option.value, value)}
                />
                
              ))
            }
          />
        </div>

      </div>
      {errors[fieldName] && (
        <p className="mt-2 text-sm text-red-600">{errors[fieldName].message}</p>
      )}
    </div>
  );
}
