import { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  BoltIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { humanLoopFeedback } from "../../utils/redux/actions/dataActions";
import { useLocation } from "react-router-dom";

export default function Notification() {
  const ui = useSelector((state) => state.ui);
  const [show, setShow] = useState(false);
  const [persistent, setPersistent] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  //Trigger notification when the UI state changes and timeout after 5 seconds
  useEffect(() => {
    if (ui.notification.show) {
      if (ui.notification.type === "feedback") {
        setShow(true);
        setPersistent(true);
      } else {
        setShow(true);
        setPersistent(false);
        beginCountdown();
      }
    }
  }, [ui.notification]);

  // Fade out the notification if the user navigates away
  useEffect(() => {
    if (persistent && show) {
      beginCountdown();
    }
  }, [location.pathname]);

  // Countdown to dismiss
  const delay = 7000;
  const beginCountdown = () => {
    setTimeout(() => {
      setShow(false);
      dispatch({ type: "CLEAR_NOTIFICATION" });
    }, delay);
  };

  // Handle HumanLoop Feedback
  const handleFeedback = (value) => {
    dispatch(
      humanLoopFeedback({
        dataId: ui.notification.meta.humanLoopDataId,
        type: "rating",
        value: value,
      })
    );
    setShow(false);
    dispatch({ type: "CLEAR_NOTIFICATION" });
  };

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:p-6 z-50"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end pb-16">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-10 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-[0_25px_60px_-15px_rgba(0,0,0,0.3)] ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                {ui.notification.type === "static" && (
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        {ui.notification.title}
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        {ui.notification.message}
                      </p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                )}
                {ui.notification.type === "error" && (
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        {ui.notification.title}
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        {ui.notification.message}
                      </p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                )}
                {ui.notification.type === "feedback" && (
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <BoltIcon
                        className="h-6 w-6 text-pink-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        {ui.notification.title}
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        {ui.notification.message}
                      </p>
                      <div className="mt-3 flex space-x-7">
                        <button
                          type="button"
                          className="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-green-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <HandThumbUpIcon
                            onClick={() => handleFeedback("good")}
                            className="mr-1 h-6 w-6"
                            aria-hidden="true"
                          />
                        </button>
                        <button
                          type="button"
                          className="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <HandThumbDownIcon
                            onClick={() => handleFeedback("bad")}
                            className="mr-1 h-6 w-6"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
