import React from "react";
import icon from "./icon.svg";
import text from "./text.svg";

export default function Logo(props) {
  let { hideIcon, hideText, width } = props;

  let logoWidth = width ? width : "w-32";

  return (
    <div className={`flex inline-block align-middle ${logoWidth}`}>
      {!hideIcon && <img src={icon} alt="Logo Icon" className="w-[25%]" />}
      <img src={text} alt="Flight" className={`w-[65%] transition-opacity duration-1000 ml-[5%] mt-[9%] mb-[7%] ${hideText ? "opacity-0" : "opacity-100"}`} />
    </div>
  );
}
