import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { quickStartExtractMessaging } from "../../../../utils/redux/actions/dataActions/notebookActions";
import { useSelector } from "react-redux";
import { get } from "lodash";
import InlineLoader from "../../../ux/InlineLoader";
import QuickStartWizardContent from "./QuickStartWizardContent";

export default function QuickStartWizard(props) {
  const { html } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const data = useSelector((state) => state.v2Data);
  const notebook = get(data, `products.${params.productId}.notebook`, {});
  const [analyzing, setAnalyzing] = useState(false);

  const handleSubmit = async (data) => {
    console.log(html)
    // setAnalyzing(true);
    // const completeAnalysis = await dispatch(
    //   quickStartExtractMessaging({
    //     html: html,
    //     organizationId: params.organizationId,
    //     productId: params.productId,
    //   })
    // );
    // navigate(`/${params.organizationId}/${params.productId}/notebook/review`);
  };

  return (
    <div>
      <QuickStartWizardContent />
      <button
        onClick={handleSubmit}
        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Submit
      </button>
      <div className="break-words text-xs">
        {analyzing && <InlineLoader />}

{notebook && notebook.humanLoopResponse && notebook.humanLoopResponse.benefits && JSON.stringify(notebook.humanLoopResponse.benefits)}

        {notebook &&
          notebook.humanLoopResponse &&
          Object.keys(notebook.humanLoopResponse).map((key, index) => {
            return (
              <div key={index} className="mt-2">
                {JSON.stringify(key)}: {JSON.stringify(notebook.humanLoopResponse[key])}
              </div>
            );
          })}
      </div>
    </div>
  );
}
