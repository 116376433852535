import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getWizardFieldSuggestions,
  insertContentIntoAsset,
  runAssetWizard,
} from "../../../../utils/redux/actions/dataActions/assetActions";
import { useForm } from "react-hook-form";

import FormTextField from "../../../ux/FormTextField";
import InlineLoader from "../../../ux/InlineLoader";
import InlineTextEditorWithSuggestions from "../../../ux/InlineTextEditorWithSuggestions";
import WizardInlineMultiSelect from "../../../ux/WizardInlineMultiSelect";
import AssetWizardMultiSelect from "../../../ux/AssetWizardSizeMultiSelect";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { get } from "lodash";
import AssetWizardFormFieldSet from "./AssetWizardFormFieldSet";
import AssetWizardPlaybookDropdown from "../../../ux/AssetWizardPlaybookDropdown";
import { useIntercom } from "react-use-intercom";

export default function AssetWizardForm(props) {
  // React Hooks
  const [HTML, setHTML] = useState("This is a test prompt");
  const [options, setOptions] = useState({});
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [accordionPanel, setAccordionPanel] = useState(null);
  const [playbook, setPlaybook] = useState(null);

  const dispatch = useDispatch();
  const params = useParams();

  // Desctructure props
  const { type, assetId, wizardFields } = props;

  // Add Intercom to Generate Action
  const { trackEvent } = useIntercom();
  
  const handleTrackEvent = () =>
    trackEvent("generate-asset-click", {
      assetId: assetId,
      type: type,
    });


    const handleDropDownTrackEvent = () =>
    trackEvent("asset-generator-dropdown-filled", {
      assetId: assetId,
      type: type,
    });


  // Get Product Information From Store
  const data = useSelector((state) => state.v2Data);
  const playbooks = get(data, `playbooks`, {});
  let productPlaybooksArray = get(
    data,
    `products.${params.productId}.playbooks`,
    []
  );

  // Get the default values for the form
  const getDefaultValues = () => {
    // Loop through every nested fieldSet and field and create an object with the fieldName as the key and the default value as the value
    const defaultValues = wizardFields.fieldSet.map((fieldSet) => {
      return fieldSet.fields.map((field) => {
        return { [field.fieldName]: field.default };
      });
    });

    // Normalize the defaultValues array
    const defaultValuesTwo = defaultValues.flat();

    // Create an object from the defaultValuesTwo array
    const defaultValuesObject = Object.assign({}, ...defaultValuesTwo);

    // Add in the default values for the target audience fields
    const targetAudienceDefaultValues = {
      name: "",
      size: ["all"],
      industries: "",
      usecases: "",
    };

    // Combine objects
    const finalDefaults = {
      ...defaultValuesObject,
      ...targetAudienceDefaultValues,
    };

    // Return the defaultValuesObject
    return finalDefaults;
  };

  // Init React Hook Form
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    control,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: getDefaultValues() });

  // Generate Asset: Call the API to generate the asset
  const generateAsset = async (data) => {
    try {
      setAccordionPanel(null);
      setStep(1);

      const req = {
        requestCode: type,
        assetId: assetId,
        wizardData: {
          ...data,
          organizationId: params.organizationId,
          productId: params.productId,
          playbookId: playbook,
        },
      };

      const request = await dispatch(runAssetWizard(req));
      handleTrackEvent();
      
      setStep(0);
    } catch (err) {
      console.log(err);
      setStep(0);
    }
  };

  // Handle Complete: Wrap form submit in a function to pass to the API
  const handleComplete = () => {
    handleSubmit(generateAsset)();
    
  };

  // Prevent form submit
  const preventFormSubmit = (e) => {
    e.preventDefault();
  };

  // Loop through every nested fieldSet and field and create a flat object the fieldName as the key and the options as the value
  const getOptions = () => {
    let options = {};

    wizardFields.fieldSet.map((fieldSet) => {
      return fieldSet.fields.map((field) => {
        if (field.options) {
          return (options[field.fieldName] = field.options);
        } else {
          return null;
        }
      });
    });

    return options;
  };

  // Get Suggestions for Each Field Based on a selected Playbook and Product
  const getSuggestions = async (playbookId) => {
    // Set Loading
    setLoading(true);
    setPlaybook(playbookId);

    // Construct an array with the fieldName for each of the wizardFields
    let requestedFields = wizardFields.fieldSet.map((fieldSet) => {
      return fieldSet.fields.map((field) => field.fieldName);
    });

    // normalize the requestedFieldsTwo array
    requestedFields = requestedFields.flat();

    let requestData = {
      playbookId: playbookId,
      productId: params.productId,
      requestedFields: requestedFields,
    };

    const request = await dispatch(getWizardFieldSuggestions(requestData));

    let wizardFormData = Object.fromEntries(
      Object.keys(request.fieldInformation).map((field) => [
        field,
        request.fieldInformation[field].value,
      ])
    );

    // Remove keys with "" values from the wizardFormData object
    Object.keys(wizardFormData).forEach(
      (key) => wizardFormData[key] === "" && delete wizardFormData[key]
    );

    // Finish up
    reset(wizardFormData);
    setOptions({
      ...request.fieldInformation,
      ...getOptions(),
    });
    setLoading(false);
    handleDropDownTrackEvent()
  };

  // Trigger the getOptions function when the wizardFields change
  useEffect(() => {
    setOptions({
      ...getOptions(),
    });
  }, [wizardFields]);

  // Open the first accordion panel when we have suggestions
  useEffect(() => {
      if (options.productName) {
        setAccordionPanel(wizardFields.fieldSet[1].id)
      }
  }, [options]);


  return (
    <>
      <div className="flex flex-col shrink-0 justify-center border-b bg-gray-100 py-4 px-4 z-10">
        <div className="">
          <h2 className="text-md  text-gray-900 truncate font-semibold">
            {wizardFields.overview.pageTitle}
          </h2>
          <p className="text-sm pt-2">
            {wizardFields.overview.pageDescription}
          </p>
        </div>
      </div>

      <form
        noValidate
        id="asset-wizard-form"
        onSubmit={preventFormSubmit}
        className="h-full justify-between flex flex-col"
      >
        <div className="flex-none border-b bg-gray-100 py-4 px-4 z-10">
          <h2 className="text-md truncate font-semibold flex items-center mb-1">
            Quick Start
          </h2>
          <p className="text-sm pb-2">
            {wizardFields.overview.playbookTooltip}
          </p>
          <AssetWizardPlaybookDropdown getSuggestions={getSuggestions} />
        </div>

        <div
          className={`grow overflow-auto h-0 flex flex-col bg-gray-100 divide-y divide-gray-300`}
        >
          {wizardFields.fieldSet.map((fieldSet, index) => {
            return (
              <AssetWizardFormFieldSet
                key={index}
                fieldSet={fieldSet}
                register={register}
                control={control}
                errors={errors}
                watch={watch}
                reset={reset}
                setValue={setValue}
                options={options}
                accordionPanel={accordionPanel}
                setAccordionPanel={setAccordionPanel}
                disabled={options.productName ? false : true}
              />
            );
          })}

          <div className="h-1 border-b"></div>
        </div>
        {(loading || step === 1) && (
          <div className="w-full bg-white flex h-full pb-96 absolute z-10 bg-opacity-70">
            <InlineLoader />
          </div>
        )}

        <div className="flex-none border-t bg-gray-100 py-2 px-4 z-10">
          <button
            className={`rounded-md p-3 ${
              step === 1 ? "bg-gray-500" : "bg-blue-500"
            } w-full text-gray-100 truncate`}
            onClick={handleComplete}
            disabled={step === 1}
          >
            {step === 1
              ? `Generating...`
              : `Generate ${wizardFields.overview.name}`}
          </button>
        </div>
      </form>
    </>
  );
}
