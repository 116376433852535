const initialState = {
  authStateKnown: false,
  authenticated: false,
  minorLoad: false,
  FBInit: false,
  loading: false,
  credentials: {},
  clearForm: "",
  organizations: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_AUTH_STATE_KNOWN":
      return {
        ...state,
        authStateKnown: true,
      };
    case "SET_AUTHENTICATED":
      return {
        ...state,
        authenticated: true,
        credentials: {
          ...action.payload.credentials,
        },
        // organizations: {
        //   ...action.payload.organizations,
        // },
      };
    // case "ADD_NEW_ORGANIZATION_TO_USER_STORE":
    //   return {
    //     ...state,
    //     organizations: {
    //       ...state.organizations,
    //       [action.payload.id]: {
    //         ...action.payload,
    //       },
    //     },
    //   };
    case "SET_UNAUTHENTICATED":
      return initialState;
    default:
      return state;
  }
}
