import { get } from "lodash";
import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateBenefitsFeaturesMatrix } from "../../../../../utils/redux/actions/dataActions/positioningActions";
import BenefitColumn from "./BenefitColumn";
import tw from "tailwind-styled-components";
import EmptyStateButton from "../../../../ux/EmptyStateButton";
import { PlusIcon } from "@heroicons/react/24/outline";

const BenefitsFeaturesMatrixContainer = tw.div`
    flex
    flex-col
    gap-4
    `;

export default function BenefitsFeaturesMatrix(props) {
  const { focusVersion, errors } = props;

  const dispatch = useDispatch();
  let params = useParams();
  const data = useSelector((state) => state.v2Data);
  let organizationId = params.organizationId; // change back to params.productId
  let productId = params.productId; // change back to params.productId
  let benefitsFeaturesMatrix = get(
    data,
    `products.${productId}.positioning.benefitsFeaturesMatrix`
  );

  const initialData = benefitsFeaturesMatrix

  // DRAG AND DROP ACTIONS
  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    // dropped outside the list or cancelled
    if (!destination) {
      return;
    }

    // dropped at the same place
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "benefit") {
      const newBenefitOrder = Array.from(initialData.benefitOrder);
      newBenefitOrder.splice(source.index, 1);
      newBenefitOrder.splice(destination.index, 0, draggableId);

      const newState = {
        ...initialData,
        benefitOrder: newBenefitOrder,
      };

      // update state
      updateState(newState);
      return;
    }

    // construct new state

    const start = initialData.benefits[source.droppableId]; // Get the Source Column
    const finish = initialData.benefits[destination.droppableId]; // Get the Final Column

    // Moving within the same list
    if (start === finish) {
      const newFeatureIds = Array.from(start.featureIds); // Create new array from the feature Ids
      newFeatureIds.splice(source.index, 1);
      newFeatureIds.splice(destination.index, 0, draggableId);

      const newBenefit = {
        ...start,
        featureIds: newFeatureIds,
      };

      const newState = {
        ...initialData,
        benefits: {
          ...initialData.benefits,
          [newBenefit.id]: newBenefit,
        },
      };

      // update state
      updateState(newState);
    }

    // Moving from one list to another
    if (start !== finish) {
      // Remove feature from source
      const startFeatureIds = Array.from(start.featureIds);
      startFeatureIds.splice(source.index, 1);
      const newStart = {
        ...start,
        featureIds: startFeatureIds,
      };

      // Add feature to destination
      const finishFeatureIds = Array.from(finish.featureIds);
      finishFeatureIds.splice(destination.index, 0, draggableId);
      const newFinish = {
        ...finish,
        featureIds: finishFeatureIds,
      };

      const newState = {
        ...initialData,
        benefits: {
          ...initialData.benefits,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      };

      // update state
      updateState(newState);
    }
  };

  // ADD A NEW BENEFIT
  const addNewBenefit = () => {
    const newBenefitId = `benefit-${Date.now()}`;
    const newBenefit = {
      id: newBenefitId,
      title: "Enter Benefit Name",
      description: "Enter benefit description",
      featureIds: [],
      proofpointIds: [],
    };

    const newBenefitOrder = Array.from(initialData.benefitOrder);
    newBenefitOrder.push(newBenefitId);

    const newState = {
      ...initialData,
      benefitOrder: newBenefitOrder,
      benefits: {
        ...initialData.benefits,
        [newBenefitId]: newBenefit,
      },
    };

    // update state
    updateState(newState);
  };

  // REMOVE A BENEFIT
  const removeBenefit = (benefitId) => {
    const newBenefitOrder = Array.from(initialData.benefitOrder);
    newBenefitOrder.splice(newBenefitOrder.indexOf(benefitId), 1);

    const { [benefitId]: removed, ...newBenefits } = initialData.benefits;

    const newState = {
      ...initialData,
      benefitOrder: newBenefitOrder,
      benefits: {
        ...newBenefits,
      },
    };

    // update state
    updateState(newState);
  };

  // EDIT PROOF POINTS
  const editProofPoint = (target, event, parent) => {
    let proofPointId;
    let value = event.target.value;
    let newState;
    const newProofPointsOrder = Array.from(
      initialData.benefits[parent].proofpointIds
    );

    if (target === "new") {
      console.log("new");
      proofPointId = `proofpoint-${Date.now()}`;
      console.log(proofPointId);
    } else {
      proofPointId = target;
    }

    if (value === "") {
      newProofPointsOrder.splice(newProofPointsOrder.indexOf(proofPointId), 1);

      const { [proofPointId]: removed, ...newProofPoints } =
        initialData.proofpoints;

      newState = {
        ...initialData,
        proofpoints: {
          ...newProofPoints,
        },
        benefits: {
          ...initialData.benefits,
          [parent]: {
            ...initialData.benefits[parent],
            proofpointIds: newProofPointsOrder,
          },
        },
      };
    } else {
      if (target === "new") {
        newProofPointsOrder.push(proofPointId);
      }

      newState = {
        ...initialData,
        proofpoints: {
          ...initialData.proofpoints,
          [proofPointId]: {
            id: proofPointId,
            content: value,
          },
        },
        benefits: {
          ...initialData.benefits,
          [parent]: {
            ...initialData.benefits[parent],
            proofpointIds: newProofPointsOrder,
          },
        },
      };
    }

    console.log(event.target.value);
    console.log(proofPointId);

    // update state
    updateState(newState);
  };

  // ADD A FEATURE
  const addNewFeature = (benefitId) => {

    const newFeatureId = `feature-${Date.now()}`;
    const newFeature = {
      id: newFeatureId,
      name: "Enter Feature Name",
      content: "Enter Feature Description",
    };

    const newState = {
      ...initialData,
      features: {
        ...initialData.features,
        [newFeatureId]: newFeature,
      },
      benefits: {
        ...initialData.benefits,
        [benefitId]: {
          ...initialData.benefits[benefitId],
          featureIds: [
            ...initialData.benefits[benefitId].featureIds,
            newFeatureId,
          ],
        },
      },
    };

    // update state
    updateState(newState);
  };


  // EDIT FEATURE
  const editFeature = (target, event, parent) => {

    let newState = {
      ...initialData,
      features: {
        ...initialData.features,
        [target]: {
          ...initialData.features[target],
          [event.target.name]: event.target.value,
        },
      },
    }


    // update state
    updateState(newState);

  };

 

  // REMOVE A FEATURE
  const removeFeature = (featureId, benefitId) => {
    const newFeatureIds = Array.from(
      initialData.benefits[benefitId].featureIds
    );
    newFeatureIds.splice(newFeatureIds.indexOf(featureId), 1);

    const { [featureId]: removed, ...newFeatures } = initialData.features;

    const newState = {
      ...initialData,
      features: {
        ...newFeatures,
      },
      benefits: {
        ...initialData.benefits,
        [benefitId]: {
          ...initialData.benefits[benefitId],
          featureIds: newFeatureIds,
        },
      },
    };

    // update state
    updateState(newState);
  };

  // UUPDATE BENEFIT DETAILS
  const updateBenefitDetails = (target, event, parent) => {
    let newContent = event.target.value;
    let newBenefit;

    if (target === "title") {
      newBenefit = {
        ...initialData.benefits[parent],
        title: newContent,
      };
    } else if (target === "description") {
      newBenefit = {
        ...initialData.benefits[parent],
        description: newContent,
      };
    }

    const newState = {
      ...initialData,
      benefits: {
        ...initialData.benefits,
        [parent]: newBenefit,
      },
    };

    // update state
    updateState(newState);
  };

  // UPDATE FEATURE TITLE OR DESCRIPTION
  

  // UPDATE STATE
  const updateState = (newState) => {
    dispatch(
      updateBenefitsFeaturesMatrix({
        focusVersion: "positioning",
        organizationId: organizationId,
        productId: productId,
        newState: newState,
      })
    );
  };

  return (
    initialData && (
      <>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="all-columns"
            direction="vertical"
            type="benefit"
          >
            {(provided) => (
              <BenefitsFeaturesMatrixContainer
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {initialData.benefitOrder.map((benefitId, index) => {
                  const benefit = initialData.benefits[benefitId];

                  const features = benefit.featureIds.map(
                    (featureId) => initialData.features[featureId]
                  );

                  const proofpoints = benefit.proofpointIds.map(
                    (proofpointId) => initialData.proofpoints[proofpointId]
                  );

                  return (
                    <>
                      <BenefitColumn
                        key={benefitId}
                        benefit={benefit}
                        features={features}
                        proofpoints={proofpoints}
                        index={index}
                        removeBenefit={removeBenefit}
                        updateBenefitDetails={updateBenefitDetails}
                        productId={productId}
                        editProofPoint={editProofPoint}
                        addNewFeature={addNewFeature}
                        removeFeature={removeFeature}
                        editFeature={editFeature}
                      />
                    </>
                  );
                })}
                {provided.placeholder}
                <EmptyStateButton
                  title="Add New Benefit"
                  icon={<PlusIcon />}
                  action={addNewBenefit}
                />
                      {errors['benefitsFeaturesMatrix'] && (
        <p className="mt-2 text-sm text-red-600">{errors['benefitsFeaturesMatrix'].message}</p>
      )}
              </BenefitsFeaturesMatrixContainer>
            )}
          </Droppable>
        </DragDropContext>
      </>
    )
  );
}
